import { Footer } from '@screen/components/common/Footer';
import { Header } from '@screen/components/common/Header';
import { FlexFullScreenStyle } from '@screen/styles/FlexFullScreenStyle';
import { MainContainerStyle } from '@screen/styles/MainContainerStyle';

export const DefaultLayout = ({ children }: PropsWithChildren): JSX.Element => (
  <FlexFullScreenStyle>
    <Header />

    <MainContainerStyle>{children}</MainContainerStyle>

    <Footer />
  </FlexFullScreenStyle>
);
