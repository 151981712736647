import styled from '@emotion/styled';

import { Form } from '@screen/components/forward/Form';
import { Modal } from '@screen/components/forward/Modal';

export const Container = styled.div`
  position: relative;
  display: flex;

  flex-grow: 1;
  flex-direction: column;
`;

const INPUT_SPACING = '14px';

export const AddProductForm = styled(Form)`
  display: grid;
  padding: 15px;
  width: 100%;

  gap: ${INPUT_SPACING};
  grid-template-columns: repeat(3, 1fr);

  border-radius: 6px 6px 0 0;
  box-shadow: var(--main-bottom-box-shadow);
  background-color: var(--color-white);

  @media (max-width: 600px) {
    --wai-max-width: 100%;

    grid-template-columns: 1fr;
  }
`;

export const ProductsContainer = styled.div`
  width: 100%;
  margin-top: -4px;
  margin-bottom: 100px;

  overflow: auto;
  padding-bottom: 4px;
`;

export const TotalPrice = styled.div`
  --space: 20px;
  --button-size: 48px;
  --button-shadow: 12px;

  position: sticky;
  bottom: 0;
  margin-top: auto;

  .container {
    position: relative;
    width: 100vw;
    margin-bottom: -16px;
    padding-bottom: var(--space);

    left: 50%;
    transform: translateX(-50%);

    background-color: var(--color-strong-blue);

    .content {
      max-width: calc(var(--hub-max-width) + (var(--box-spacing) * 2));
      margin: 0 auto;
      padding-top: calc(var(--space) + var(--button-shadow));

      text-align: center;
      color: var(--color-white);

      .title {
        text-transform: uppercase;

        font-weight: 600;
        font-size: 1.4rem;
      }

      .price {
        font-size: 1.8rem;
        font-weight: 900;
      }
    }

    .checkout-button {
      display: flex;
      margin: 0 auto;
      margin-bottom: calc((var(--button-size) / 2) * -1);
      transform: translateY(calc((var(--button-size) / 2) * -1));

      width: var(--button-size);
      height: var(--button-size);

      align-items: center;
      justify-content: center;

      border-radius: 50%;
      border: 2px solid var(--color-favorite);
      background-color: var(--color-main-background);
      box-shadow: 0 0 0 var(--button-shadow) var(--color-main-background);

      svg {
        width: 36px;
        height: auto;

        color: var(--color-favorite);
      }
    }
  }
`;

export const FinishSaleContainer = styled(Modal)`
  --padding: 40px;

  width: 100%;
  max-width: 600px;
  overflow: hidden;

  box-shadow: var(--main-bottom-box-shadow);

  background-color: var(--color-white);
  border-radius: var(--main-bigger-border-radius);

  .wrapper {
    overflow: auto;
    max-height: calc(100vh - 20px);
  }

  .main {
    display: flex;
    padding: var(--padding);

    gap: 38px;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  .footer {
    padding: var(--padding);
    background-color: var(--color-white-grey);
  }

  .close-button {
    margin: 12px;
  }

  @media (max-width: 600px) {
    height: 100%;
    border-radius: 0;
  }
`;
