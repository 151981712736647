import { Route, Switch } from 'react-router-dom';

import { useBuildRoute } from '@resources/hooks/useBuildRoute';
import { Sellers } from '@screen/pages/Sellers';
import { SellerCreate } from '@screen/pages/Sellers/pages/Create';
import { SellerEdit } from '@screen/pages/Sellers/pages/Edit';

export const SellersRoutes = (): JSX.Element => {
  const { make } = useBuildRoute('/sellers');

  return (
    <Switch>
      <Route path={make('/')} exact>
        <Sellers />
      </Route>

      <Route path={make('/create')}>
        <SellerCreate />
      </Route>

      <Route path={make('/:id')}>
        <SellerEdit />
      </Route>
    </Switch>
  );
};
