interface Discount {
  type: number;
  amount: number;
}

export function getDiscountValue(
  totalValue: number,
  discount: Discount,
): number {
  const { type, amount } = discount;

  if (type === 0) {
    return amount;
  }

  return (totalValue / 100) * amount;
}
