import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Form } from '@screen/components/forward/Form';

const BUTTON_SIZE = '40px';
const BUTTON_MARGIN = '10px';

export const Options = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  section {
    display: flex;
    margin: 0 auto;

    max-width: var(--hub-max-width);

    align-items: center;
    justify-content: space-between;
  }

  button {
    display: flex;
    flex-shrink: 0;

    align-items: center;
    justify-content: center;

    width: ${BUTTON_SIZE};
    height: ${BUTTON_SIZE};
    margin-right: ${BUTTON_MARGIN};

    border-radius: 50%;
    color: var(--color-white);
    background-color: var(--color-favorite);

    svg {
      width: calc(${BUTTON_SIZE} * 0.5);
      height: auto;
    }
  }
`;

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;

  gap: 20px;
  align-items: center;
`;

const INPUT_SPACING = '18px';

export const Box = styled.section`
  --wai-max-width: 100%;

  display: grid;
  padding: 15px;

  width: 100%;
  gap: ${INPUT_SPACING};

  grid-template-columns: repeat(2, 1fr);
  border-radius: 6px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

interface ScopedInput {
  size?: string[];
}

export const ScopedInput = styled.section<ScopedInput>`
  display: flex;
  width: 100%;
  gap: ${INPUT_SPACING};

  ${({ size = [] }) =>
    size.map(
      (value, index) => css`
        > div:nth-child(${index + 1}) {
          width: ${value};
        }
      `,
    )}
`;
