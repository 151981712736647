import moment from 'moment';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import {
  Container,
  InfoContainer,
  SellerContainer,
  CustomerContainer,
} from './styles';

import { getDiscountValue } from '@modules/utils/getDiscountValue';
import { getShippingValue } from '@modules/utils/getShippingValue';

interface Props extends SaleDocument {
  href: string;
}

export const Card = ({
  href,
  seller,
  discount,
  shipping,
  created_at,
  amount,
  customer,
}: Props): JSX.Element => {
  const discountValue = useMemo(() => {
    if (!discount) {
      return 0;
    }

    return getDiscountValue(amount, discount);
  }, [amount, discount]);

  const shippingValue = useMemo<number | null>(() => {
    if (!shipping) {
      return null;
    }

    return getShippingValue(amount, shipping);
  }, [shipping, amount]);

  return (
    <Container as={Link} to={href}>
      <InfoContainer>
        <div className="sticker">
          <span />
        </div>

        <section>
          <h3>{moment(created_at).format('LLL')}</h3>

          <h4>
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(amount - (discountValue ?? 0) + (shippingValue ?? 0))}
          </h4>
        </section>
      </InfoContainer>

      {customer && (
        <CustomerContainer>
          <img src="/assets/avatar-preview.png" alt="Avatar" />
          <section>
            <h5>CLIENTE RELACIONADO</h5>
            <h4>{customer.full_name}</h4>
          </section>
        </CustomerContainer>
      )}

      {seller && (
        <SellerContainer>
          <img src="/assets/avatar-preview.png" alt="Avatar" />

          <section>
            <h5>VENDEDOR</h5>
            <h4>{seller.full_name}</h4>
          </section>
        </SellerContainer>
      )}
    </Container>
  );
};
