import { mergeClassNames } from '@hitechline/reactools';
import { FormContext as UnformContext } from '@unform/core';
import { DiscountKindType, ShippingKindType } from '@word/constants';
import { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { FiCheck, FiPercent, FiDollarSign } from 'react-icons/fi';

import { Container, Section, DiscountManager } from './styles';

import { api } from '@modules/services/api';
import { FormContext } from '@screen/components/forward/Form';
import {
  SelectWithLiveSearch,
  SelectOption,
} from '@screen/components/ui/SelectWithLiveSearch';

export const Association = (): JSX.Element => {
  const discountValueInputRef = useRef<HTMLInputElement>(null);
  const shippingValueInputRef = useRef<HTMLInputElement>(null);

  const [options, updateOptions] = useState<SelectOption<string>[]>([]);

  const { getUnformRef } = useContext(FormContext);
  const { registerField, unregisterField } = useContext(UnformContext);

  const [discount, updateDiscount] = useState({
    active: false,
    type: 0,
  });
  const [shipping, updateShipping] = useState({
    active: false,
    type: 0,
  });
  const [customer, updateCustomer] = useState({
    active: false,
  });

  const handleDiscountSwitch = useCallback(() => {
    updateDiscount(currentState => ({
      ...currentState,
      active: !currentState.active,
    }));
  }, []);

  const handleShippingSwitch = useCallback(() => {
    updateShipping(currentState => ({
      ...currentState,
      active: !currentState.active,
    }));
  }, []);

  const handleCustomerSwitch = useCallback(() => {
    updateCustomer(currentState => ({
      ...currentState,
      active: !currentState.active,
    }));
  }, []);

  const handleCustomerSearch = useCallback(async (search: string) => {
    if (!search) {
      updateOptions([]);
      return;
    }

    const { data: customers } = await api.get<CustomerHealthyDocument[]>(
      `/customers/search?search=${search}`,
    );

    updateOptions(
      customers.map(({ _id, full_name }) => ({
        value: _id,
        label: full_name,
      })),
    );
  }, []);

  const setDiscountType = useCallback((type: DiscountKindType) => {
    updateDiscount(currentState => ({
      ...currentState,
      type,
    }));
  }, []);

  const setShippingType = useCallback((type: ShippingKindType) => {
    updateShipping(currentState => ({
      ...currentState,
      type,
    }));
  }, []);

  useEffect(() => {
    registerField({
      name: 'discount',
      getValue() {
        if (discount.active) {
          return {
            type: discount.type,
            amount: Number(discountValueInputRef.current?.value ?? 0),
          };
        }

        return null;
      },
    });

    return () => {
      unregisterField('discount');
    };
  }, [discount, registerField, unregisterField]);

  useEffect(() => {
    registerField({
      name: 'shipping',
      getValue() {
        if (shipping.active) {
          return {
            type: shipping.type,
            amount: Number(shippingValueInputRef.current?.value ?? 0),
          };
        }

        return null;
      },
    });

    return () => {
      unregisterField('shipping');
    };
  }, [shipping, registerField, unregisterField]);

  useEffect(() => {
    registerField({
      name: 'customer',
      getValue() {
        if (customer.active) {
          return getUnformRef().current?.getFieldValue('customer_id');
        }

        return null;
      },
    });

    return () => {
      unregisterField('customer');
    };
  }, [customer, getUnformRef, registerField, unregisterField]);

  return (
    <Container>
      <Section
        className={mergeClassNames({
          active: discount.active,
        })}
      >
        <div className="header">
          <button type="button" onClick={handleDiscountSwitch}>
            <FiCheck />
          </button>

          <span>Adicionar Desconto</span>
        </div>

        <div className="content">
          <DiscountManager>
            <button
              type="button"
              onClick={() => setDiscountType(0)}
              className={mergeClassNames({
                selected: discount?.type === 0,
              })}
            >
              <FiDollarSign />
            </button>

            <button
              type="button"
              onClick={() => setDiscountType(1)}
              className={mergeClassNames({
                selected: discount?.type === 1,
              })}
            >
              <FiPercent />
            </button>

            <input defaultValue="0" ref={discountValueInputRef} />
          </DiscountManager>
        </div>
      </Section>

      <Section
        className={mergeClassNames({
          active: shipping.active,
        })}
      >
        <div className="header">
          <button type="button" onClick={handleShippingSwitch}>
            <FiCheck />
          </button>

          <span>Adicionar Frete</span>
        </div>

        <div className="content">
          <DiscountManager>
            <button
              type="button"
              onClick={() => setShippingType(0)}
              className={mergeClassNames({
                selected: shipping?.type === 0,
              })}
            >
              <FiDollarSign />
            </button>

            <button
              type="button"
              onClick={() => setShippingType(1)}
              className={mergeClassNames({
                selected: shipping?.type === 1,
              })}
            >
              <FiPercent />
            </button>

            <input defaultValue="0" ref={shippingValueInputRef} />
          </DiscountManager>
        </div>
      </Section>

      <Section
        className={mergeClassNames({
          active: customer.active,
        })}
      >
        <div className="header">
          <button type="button" onClick={handleCustomerSwitch}>
            <FiCheck />
          </button>

          <span>Associar Cliente</span>
        </div>

        <div className="content">
          <SelectWithLiveSearch
            name="customer_id"
            title="Nome do cliente"
            options={options}
            onSearchChange={handleCustomerSearch}
          />
        </div>
      </Section>
    </Container>
  );
};
