import { createContext, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { api } from '@modules/services/api';
import { actions } from '@store/modules/auth/actions';

type User = (EmployeeHealthyDocument | SellerHealthyDocument) & {
  perm: number;
};

interface APILoginData {
  token: string;
  user: EmployeeHealthyDocument;
}

interface AuthenticateData {
  email: string;
  password: string;
}

export interface AuthContextData {
  loaded: boolean;
  user: Partial<User>;
  retrieveEmployee: () => Promise<void>;
  setUser: (user: User) => void;
  authenticate(data: AuthenticateData): Promise<void>;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);

export const AuthProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { signed } = useSelector(({ auth }) => auth);

  const [loaded, setLoaded] = useState(false);
  const [user, updateUser] = useState<Partial<User>>({});

  const setUser = useCallback(data => {
    updateUser({
      ...data,
      perm: data.permission ?? -1,
    });
  }, []);

  const authenticate = useCallback(
    async ({ email, password }: AuthenticateData) => {
      const {
        data: { token, user: userData },
      } = await api.post<APILoginData>('sessions/login', { email, password });

      setUser(userData);
      setLoaded(true);

      setTimeout(() => {
        dispatch(actions.signInRequest({ token }));

        push('/app');
      }, 0);
    },
    [push, dispatch, setUser],
  );

  const retrieveEmployee = useCallback(async () => {
    try {
      const { data } = await api.get('sessions/login/@me');
      setUser(data);
    } catch {
      setLoaded(false);
      dispatch(actions.logOut({}));
    }
  }, [dispatch, setUser]);

  useEffect(() => {
    if (!signed || loaded) {
      return;
    }

    retrieveEmployee();
  }, [loaded, signed, retrieveEmployee]);

  return (
    <AuthContext.Provider
      value={{ loaded, user, authenticate, setUser, retrieveEmployee }}
    >
      {children}
    </AuthContext.Provider>
  );
};
