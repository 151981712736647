import { createContext, useState, useCallback } from 'react';

import { StandardLoader } from '@screen/components/ui/StandardLoader';

export interface UIContextData {
  hasOpenedWarnModal: boolean;
  setHasOpenedWarnModal(): void;
  setLoading(isLoading: boolean): void;
}

export const UIContext = createContext({} as UIContextData);

export const UIProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const [loading, updateLoading] = useState(false);
  const [hasOpenedWarnModal, updateHasOpenedWarnModal] = useState(false);

  const setLoading = useCallback((isLoading: boolean) => {
    updateLoading(Boolean(isLoading));
  }, []);

  const setHasOpenedWarnModal = useCallback(() => {
    updateHasOpenedWarnModal(true);
  }, []);

  return (
    <UIContext.Provider
      value={{
        setLoading,
        hasOpenedWarnModal,
        setHasOpenedWarnModal,
      }}
    >
      {loading && <StandardLoader />}

      <div id="app">{children}</div>
    </UIContext.Provider>
  );
};
