import styled from '@emotion/styled';

export const Container = styled.div`
  text-align: center;

  p {
    color: var(--color-strong-grey);
  }

  .main-price {
    font-size: 1.8rem;
  }

  .discount-aplicated {
    color: var(--color-light-red);
  }

  .shipping-aplicated {
    color: var(--color-strong-blue);
  }

  .divider {
    height: 1px;
    width: 100%;

    margin: 4px auto;
    max-width: 200px;

    background-color: var(--color-grey);
  }

  .button {
    margin-top: 20px;
  }
`;
