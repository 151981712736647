import { string, object, number } from 'yup';

const numberSchema = number().transform((currentValue, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return undefined;
  }

  return currentValue;
});

export const productCreateSchema = object().shape({
  category: string(),
  sku_code: string(),
  provider_id: string(),
  cost_price: numberSchema,
  sale_price: numberSchema,
  name: string().required('Insira o nome do produto'),
});

export const productEditSchema = object().shape({
  category: string(),
  sku_code: string(),
  provider_id: string(),
  cost_price: numberSchema,
  sale_price: numberSchema,
  name: string().required('Insira o nome do produto'),
});
