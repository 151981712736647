import { Scope } from '@unform/core';
import {
  HOUSE_KIND,
  MARITAL_STATUS,
  HouseKindType,
  MaritalStatusType,
} from '@word/constants';
import { useEffect, useContext, useCallback, useRef } from 'react';
import {
  AiOutlineMail,
  AiOutlineIdcard,
  AiOutlineFieldNumber,
} from 'react-icons/ai';
import {
  BiUserCircle,
  BiMapAlt,
  BiDirections,
  BiStreetView,
} from 'react-icons/bi';
import { FiPhone } from 'react-icons/fi';
import { GoLocation } from 'react-icons/go';
import { MdLocationCity } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Container, Box, ScopedInput } from './styles';

import { api } from '@modules/services/api';
import { apply } from '@resources/cases/apply';
import { Logged } from '@resources/cases/Logged';
import { CEPManager } from '@resources/managers/CEPManager';
import { sellerCreateSchema } from '@resources/schemas/seller';
import type { FormSubmit, FormHandles } from '@screen/components/forward/Form';
import { Input } from '@screen/components/ui/Input';
import { SaveButton } from '@screen/components/ui/SaveButton';
import { Select, SelectOption } from '@screen/components/ui/Select';
import {
  BackHeaderLayout,
  BackHeaderLayoutContext,
} from '@screen/layouts/BackHeaderLayout';

interface FormSellerData {
  phone: string;
  email: string;
  document: string;
  full_name: string;
  college: string;
  home_type: string;
  home_phone: string;
  marital_status: string;
  address: {
    city: string;
    state: string;
    street: string;
    number: string;
    zip_code: string;
    neighborhood: string;
  };
}

export const SellerCreate = apply(
  (): JSX.Element => {
    const formRef = useRef<FormHandles>(null);

    const history = useHistory();
    const { setTitle } = useContext(BackHeaderLayoutContext);

    const handleSubmit: FormSubmit<FormSellerData> = useCallback(
      async data => {
        const { data: sellerId } = await api.post('sellers', data);

        history.push(`/sellers/${sellerId}`);
        toast.success('Vendedor cadastrado com sucesso!');
      },
      [history],
    );

    useEffect(() => {
      setTitle('Cadastro de vendedor');
    }, [setTitle]);

    return (
      <Container
        ref={formRef}
        schema={sellerCreateSchema}
        onSubmit={handleSubmit}
      >
        <CEPManager>
          <Box>
            <Input
              icon={BiUserCircle}
              name="full_name"
              placeholder="Nome Completo"
            />
            <Input icon={AiOutlineMail} name="email" placeholder="E-mail" />
            <Select
              name="marital_status"
              title="Estado civil"
              icon={BiUserCircle}
              options={maritalStatusOptions}
            />
            <Input
              name="document"
              mask="999.999.999-99"
              placeholder="CPF"
              icon={AiOutlineIdcard}
            />

            <Input
              name="phone"
              mask="(99) \9 9999-9999"
              placeholder="Telefone Celular"
              icon={FiPhone}
            />
            <Input
              icon={FiPhone}
              mask="(99) \9 9999-9999"
              name="house_phone"
              placeholder="Telefone Residencial"
            />
          </Box>

          <Box>
            <Scope path="address">
              <Input icon={BiMapAlt} name="zip_code" placeholder="CEP" />
              <Input icon={BiDirections} name="street" placeholder="Rua" />

              <ScopedInput size={['50%']}>
                <Input
                  icon={AiOutlineFieldNumber}
                  name="number"
                  placeholder="Número"
                />
                <Input
                  icon={BiStreetView}
                  name="neighborhood"
                  placeholder="Bairro"
                />
              </ScopedInput>
              <ScopedInput>
                <Input icon={MdLocationCity} name="city" placeholder="Cidade" />
                <Input icon={GoLocation} name="state" placeholder="Estado" />
              </ScopedInput>
            </Scope>
          </Box>

          <Box>
            <Select
              name="house_kind"
              title="Tipo de Residencia"
              icon={MdLocationCity}
              options={houseKindOptions}
            />
            <Input
              icon={BiUserCircle}
              name="college"
              placeholder="Grau de Formação"
            />
          </Box>

          <SaveButton />
        </CEPManager>
      </Container>
    );
  },
  {
    layout: BackHeaderLayout,
    cases: [Logged],
  },
);

const maritalStatusOptions: SelectOption<MaritalStatusType>[] = Object.entries(
  MARITAL_STATUS,
).map(([value, name]) => ({
  label: name,
  value: Number(value) as MaritalStatusType,
}));

const houseKindOptions: SelectOption<HouseKindType>[] = Object.entries(
  HOUSE_KIND,
).map(([value, name]) => ({
  label: name,
  value: Number(value) as HouseKindType,
}));
