import { Route, Switch } from 'react-router-dom';

import { useBuildRoute } from '@resources/hooks/useBuildRoute';
import { Providers } from '@screen/pages/Providers';
import { ProviderCreate } from '@screen/pages/Providers/pages/Create';
import { ProviderEdit } from '@screen/pages/Providers/pages/Edit';

export const ProvidersRoutes = (): JSX.Element => {
  const { make } = useBuildRoute('/providers');

  return (
    <Switch>
      <Route path={make('/')} exact>
        <Providers />
      </Route>

      <Route path={make('/create')}>
        <ProviderCreate />
      </Route>

      <Route path={make('/:id')}>
        <ProviderEdit />
      </Route>
    </Switch>
  );
};
