import { Route, Switch, Redirect } from 'react-router-dom';

import { CustomerRoutes } from './Customer.routes';
import { EmployeeRoutes } from './Employee.routes';
import { MainRoutes } from './Main.routes';
import { ProductRoutes } from './Product.routes';
import { ProvidersRoutes } from './Provider.routes';
import { ReportRoutes } from './Report.routes';
import { SaleRoutes } from './Sale.routes';
import { SellersRoutes } from './Seller.routes';

export const RoutesManager = (): JSX.Element => (
  <Switch>
    <Route path="/customers">
      <CustomerRoutes />
    </Route>

    <Route path="/providers">
      <ProvidersRoutes />
    </Route>

    <Route path="/sellers">
      <SellersRoutes />
    </Route>

    <Route path="/sales">
      <SaleRoutes />
    </Route>

    <Route path="/products">
      <ProductRoutes />
    </Route>

    <Route path="/employees">
      <EmployeeRoutes />
    </Route>

    <Route path="/reports">
      <ReportRoutes />
    </Route>

    <Route path="/">
      <MainRoutes />
    </Route>

    {/* 404 */}

    <Route path="*">
      <Redirect to="/" />
    </Route>
  </Switch>
);
