import { useAsync, mergeClassNames } from '@hitechline/reactools';
import moment from 'moment';
import {
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
} from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { FiTrash2, FiX } from 'react-icons/fi';
import { useHistory, useParams, Link } from 'react-router-dom';

import {
  Container,
  InfoContainer,
  FooterContainer,
  ProductsContainer,
  OptionsContainer,
  DeleteModal,
} from './styles';

import { api } from '@modules/services/api';
import { apply } from '@resources/cases/apply';
import { Logged } from '@resources/cases/Logged';
import type { ModalHandles } from '@screen/components/forward/Modal';
import { Button } from '@screen/components/ui/Button';
import { Spinner } from '@screen/components/ui/Spinner';
import {
  BackHeaderLayout,
  BackHeaderLayoutContext,
} from '@screen/layouts/BackHeaderLayout';

export const Success = apply(
  (): JSX.Element => {
    const history = useHistory();

    const { id } = useParams<{ id: string }>();
    const { setTitle } = useContext(BackHeaderLayoutContext);
    const modalRef = useRef<ModalHandles>(null);

    const [inDelete, updateInDelete] = useState(false);

    const closeDeleteModal = useCallback(() => {
      modalRef.current?.close();
    }, []);

    const openDeleteModal = useCallback(() => {
      modalRef.current?.open();
    }, []);

    const {
      error,
      loading,
      data: sale,
    } = useAsync(() =>
      api.get<SaleHealthyDocument>(`sales/${id}`).then(({ data }) => data),
    );

    const {
      data: salePDF,
      error: errorPDF,
      loading: loadingPDF,
    } = useAsync(() =>
      api
        .get<Blob>(`sales/${id}/pdf`, { responseType: 'blob' })
        .then(({ data }) => data),
    );

    const hasPDF = useMemo(
      () => !loadingPDF && !errorPDF && salePDF,
      [loadingPDF, errorPDF, salePDF],
    );

    const viewPDF = useCallback(() => {
      if (!hasPDF) {
        return;
      }

      const url = URL.createObjectURL(salePDF);

      window.open(url);
    }, [hasPDF, salePDF]);

    const handleDelete = useCallback(async () => {
      updateInDelete(true);

      try {
        await api.delete<SaleHealthyDocument>(`sales/${id}`);
        history.push('/sales');
      } finally {
        closeDeleteModal();
        updateInDelete(false);
      }
    }, [id, history, closeDeleteModal]);

    useEffect(() => {
      setTitle(sale ? `ID #${sale._id}` : 'Carregando...');
    }, [sale, setTitle]);

    useEffect(() => {
      if (error) {
        history.push('/customers');
      }
    }, [error, history]);

    return loading ? (
      <Spinner size="30px" />
    ) : (
      <Container>
        <InfoContainer className="info">
          <div>
            <h4>Cliente</h4>
            <p>{sale?.customer?.full_name ?? 'Não informado'}</p>
          </div>

          <DeleteModal ref={modalRef}>
            <button type="button" className="close" onClick={closeDeleteModal}>
              <FiX />
            </button>

            <p>Deseja continuar e deletar esta venda?</p>

            <Button
              onClick={handleDelete}
              className={mergeClassNames('continue', { loading: inDelete })}
            >
              <Spinner color="#fff" className="spinner" />
              <span>Continuar</span>
              <FaLongArrowAltRight />
            </Button>
          </DeleteModal>

          <button type="button" onClick={openDeleteModal}>
            <FiTrash2 />
          </button>
        </InfoContainer>

        <ProductsContainer className="products">
          {sale?.cart.map(({ product, quantity }, index) => (
            <div className="item" key={product?._id ?? index}>
              <section className="name">
                <p>{product?.name ?? 'N/A'}</p>
                <span className="quantity">x{quantity}</span>
              </section>

              <section className="price">
                <p>
                  {product ? (
                    Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(product?.sale_price)
                  ) : (
                    <span>N/A</span>
                  )}
                </p>
              </section>
            </div>
          ))}
        </ProductsContainer>

        <OptionsContainer>
          <Link className="button create" to="/sales/create">
            Nova Venda
          </Link>

          <button className="button pdf" type="button" onClick={viewPDF}>
            {hasPDF ? `${sale?._id}.pdf` : <Spinner size="12px" />}
          </button>
        </OptionsContainer>

        <FooterContainer>
          <p className="date">{moment(sale?.created_at).format('LLLL')}</p>
        </FooterContainer>
      </Container>
    );
  },
  {
    layout: BackHeaderLayout,
    cases: [Logged],
  },
);
