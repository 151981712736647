import { useEffect, useContext, useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Container, Box } from './styles';

import { api } from '@modules/services/api';
import { apply } from '@resources/cases/apply';
import { Logged } from '@resources/cases/Logged';
import { productCreateSchema } from '@resources/schemas/product';
import type { FormSubmit, FormHandles } from '@screen/components/forward/Form';
import { Input } from '@screen/components/ui/Input';
import { SaveButton } from '@screen/components/ui/SaveButton';
import {
  SelectWithLiveSearch,
  SelectOption,
} from '@screen/components/ui/SelectWithLiveSearch';
import {
  BackHeaderLayout,
  BackHeaderLayoutContext,
} from '@screen/layouts/BackHeaderLayout';

interface FormProductData {
  phone: string;
  email: string;
  document: string;
  full_name: string;
  address: {
    city: string;
    state: string;
    street: string;
    number: string;
    zip_code: string;
    neighborhood: string;
  };
}

export const ProductCreate = apply(
  (): JSX.Element => {
    const formRef = useRef<FormHandles>(null);

    const history = useHistory();
    const { setTitle } = useContext(BackHeaderLayoutContext);

    const [options, updateOptions] = useState<SelectOption<string>[]>([]);

    const handleSubmit: FormSubmit<FormProductData> = useCallback(
      async data => {
        const { data: customerId } = await api.post('products', data);

        history.push(`/products/${customerId}`);
        toast.success('Produto cadastrado com sucesso!');
      },
      [history],
    );

    const handleProviderSearch = useCallback(async (search: string) => {
      if (!search) {
        updateOptions([]);
        return;
      }

      const { data: providers } = await api.get<ProviderHealthyDocument[]>(
        `/providers/search?search=${search}`,
      );

      updateOptions(
        providers.map(({ _id, company_name }) => ({
          value: _id,
          label: company_name,
        })),
      );
    }, []);

    useEffect(() => {
      setTitle('Novo Produto');
    }, [setTitle]);

    return (
      <Container
        ref={formRef}
        schema={productCreateSchema}
        onSubmit={handleSubmit}
      >
        <Box>
          <Input name="name" placeholder="Nome do produto" />
          <Input name="sku_code" placeholder="Código SKU" />
          <Input
            name="sale_price"
            pattern="[0-9.]{0,}"
            placeholder="Preço de venda"
          />
          <Input
            name="cost_price"
            pattern="[0-9.]{0,}"
            placeholder="Preço de custo"
          />
          <Input name="category" placeholder="Categoria" />
        </Box>

        <Box>
          <SelectWithLiveSearch
            name="provider_id"
            title="Fornecedor"
            options={options}
            onSearchChange={handleProviderSearch}
          />
        </Box>

        <SaveButton />
      </Container>
    );
  },
  {
    layout: BackHeaderLayout,
    cases: [Logged],
  },
);
