import styled from '@emotion/styled';

export const Container = styled.table`
  width: 100%;
  table-layout: fixed;

  border-spacing: 0;
  box-shadow: var(--main-bottom-box-shadow);

  td,
  th {
    padding: 1.8rem 1.6rem;
  }

  th {
    text-align: left;
    font-weight: 400;

    color: var(--color-white);
    background-color: var(--color-favorite);
  }

  tbody tr {
    background: var(--color-white);

    &:nth-child(even) {
      background-color: var(--color-white-grey);
    }
  }

  td {
    color: var(--color-black);

    &:first-child {
      color: var(--color-black);
    }
  }

  .discount-prod {
    color: var(--color-light-red);
  }

  .buttons {
    display: flex;
    gap: 12px;

    button {
      --button-size: 32px;

      display: flex;
      width: var(--button-size);
      height: var(--button-size);

      align-items: center;
      justify-content: center;

      color: var(--color-white);
      background-color: var(--color-favorite);

      border-radius: 50%;

      svg {
        width: 20px;
        height: auto;
      }
    }
  }
`;
