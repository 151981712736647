import styled from '@emotion/styled';

import { Modal } from '@screen/components/forward/Modal';

export const Container = styled(Modal)`
  --padding: 40px;

  width: 100%;
  max-width: 600px;
  overflow: hidden;

  box-shadow: var(--main-bottom-box-shadow);

  background-color: var(--color-white);
  border-radius: var(--main-bigger-border-radius);

  .main {
    padding: var(--padding);

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  .close-button {
    margin: 12px;
  }

  .content {
    margin-top: 16px;

    opacity: 0.5;
    pointer-events: none;
  }

  .header {
    display: flex;
    align-items: center;

    span {
      margin-left: 8px;
    }

    button {
      display: flex;
      width: 26px;
      height: 26px;

      align-items: center;
      justify-content: center;

      color: var(--color-white);

      border-radius: 6px;
      border: 2px solid var(--color-favorite);

      svg {
        display: none;
        width: 100%;
        height: auto;
      }
    }
  }

  &.active {
    .content {
      opacity: 1;
      pointer-events: all;
    }

    .header button {
      background-color: var(--color-favorite);

      svg {
        display: inherit;
      }
    }
  }

  @media (max-width: 600px) {
    height: 100%;
    border-radius: 0;
  }
`;

export const DiscountManager = styled.div`
  display: flex;
  overflow: hidden;
  width: max-content;

  border-radius: 8px;
  border: 1px solid var(--color-white-grey);

  input {
    width: 70px;
    padding: 12px;

    text-align: center;
  }

  button {
    width: 56px;
    height: 56px;

    color: var(--color-grey);
    background-color: var(--color-white-grey);

    &.selected {
      color: var(--color-white);
      background-color: var(--color-favorite);
    }
  }
`;
