import { AiOutlineIdcard } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import { Container, CustomerInfo } from './styles';

interface Props {
  href: string;
  document: string;
  companyName: string;
}

export const Card = ({ document, companyName, href }: Props): JSX.Element => (
  <Container as={Link} to={href}>
    <h3>{companyName}</h3>
    <CustomerInfo>
      <div>
        <AiOutlineIdcard size="20px" />
        <h4>{document}</h4>
      </div>
    </CustomerInfo>
  </Container>
);
