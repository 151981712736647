import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;

  textarea {
    width: 100%;
    max-width: 400px;
    min-height: 110px;
    padding: 12px;

    resize: vertical;

    border-radius: var(--wai-main-border-radius);
    border: 1px solid var(--color-white-grey);

    &:focus {
      border-color: var(--color-favorite);
    }
  }
`;
