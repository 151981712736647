import { Container } from './styles';

export const NotImplemented = (): JSX.Element => (
  <Container>
    <h1>SETOR EM DESENVOLVIMENTO!</h1>

    <h3>
      O SETOR DE RELÁTORIOS ESTÁ EM DESENVOLVIMENTO, FAVOR AGUARDAR NOVAS
      ATUALIZAÇÕES
    </h3>

    <p>
      Para mais informações sobre o andamento, entre em contato com a empresa
      responsável pelo desenvolvimento.
    </p>
  </Container>
);
