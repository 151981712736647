import { useAsync, mergeClassNames } from '@hitechline/reactools';
import { Scope } from '@unform/core';
import { useContext, useState, useEffect, useRef, useCallback } from 'react';
import {
  AiOutlineMail,
  AiOutlineIdcard,
  AiOutlineFieldNumber,
} from 'react-icons/ai';
import {
  BiUserCircle,
  BiMapAlt,
  BiDirections,
  BiStreetView,
} from 'react-icons/bi';
import { FaShoppingCart, FaLongArrowAltRight } from 'react-icons/fa';
import { FiTrash2, FiPhone, FiX } from 'react-icons/fi';
import { GoLocation } from 'react-icons/go';
import { MdLocationCity } from 'react-icons/md';
import { TiPencil } from 'react-icons/ti';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Container,
  Form,
  DeleteModal,
  Options,
  Box,
  ScopedInput,
} from './styles';

import { Logged } from '@/resources/cases/Logged';
import {
  BackHeaderLayout,
  BackHeaderLayoutContext,
} from '@/screen/layouts/BackHeaderLayout';
import { api } from '@modules/services/api';
import { apply } from '@resources/cases/apply';
import { useCPFOrCPNJInput } from '@resources/hooks/useCPFOrCPNJInput';
import { CEPManager } from '@resources/managers/CEPManager';
import { customerEditSchema } from '@resources/schemas/customer';
import type { FormSubmit, FormHandles } from '@screen/components/forward/Form';
import type { ModalHandles } from '@screen/components/forward/Modal';
import { Button } from '@screen/components/ui/Button';
import { InputEdit } from '@screen/components/ui/InputEdit';
import { SaveButton } from '@screen/components/ui/SaveButton';
import { Spinner } from '@screen/components/ui/Spinner';

export const CustomerEdit = apply(
  (): JSX.Element => {
    const history = useHistory();
    const formRef = useRef<FormHandles>(null);
    const modalRef = useRef<ModalHandles>(null);

    const [editable, updateEditable] = useState(false);
    const [inDelete, updateInDelete] = useState(false);

    const { id } = useParams<{ id: string }>();
    const { setTitle } = useContext(BackHeaderLayoutContext);
    const documentInputProps = useCPFOrCPNJInput();

    const handleEdit = useCallback(() => {
      updateEditable(oldValue => !oldValue);
    }, []);

    const closeDeleteModal = useCallback(() => {
      modalRef.current?.close();
    }, []);

    const openDeleteModal = useCallback(() => {
      modalRef.current?.open();
    }, []);

    const getCustomerData = useCallback(
      () =>
        api
          .get<CustomerHealthyDocument>(`customers/${id}`)
          .then(({ data }) => data),
      [id],
    );

    const {
      error,
      loading,
      data: customerData,
    } = useAsync(() =>
      api
        .get<CustomerHealthyDocument>(`customers/${id}`)
        .then(({ data }) => data),
    );

    const handleSubmit: FormSubmit<any> = useCallback(
      async data => {
        await api.put<CustomerHealthyDocument>(`customers/${id}`, data);

        updateEditable(false);
        toast.success(`${customerData?.full_name} atualizado com sucesso`);

        const newCustomerData = await getCustomerData();

        formRef.current?.getUnformRef().current?.setData(newCustomerData);
      },
      [id, customerData, getCustomerData],
    );

    const handleDelete = useCallback(async () => {
      updateInDelete(true);

      try {
        await api.delete<CustomerHealthyDocument>(`customers/${id}`);
        history.push('/customers');
      } finally {
        closeDeleteModal();
        updateInDelete(false);
      }
    }, [id, history, closeDeleteModal]);

    useEffect(() => {
      setTitle('Detalhes Cliente');
    }, [setTitle]);

    useEffect(() => {
      if (error) {
        history.push('/customers');
      }
    }, [error, history]);

    return (
      <Container>
        <DeleteModal ref={modalRef}>
          <button type="button" className="close" onClick={closeDeleteModal}>
            <FiX />
          </button>

          <p>
            Deseja continuar e deletar o cliente{' '}
            <strong>{customerData?.full_name}</strong>?
          </p>

          <Button
            onClick={handleDelete}
            className={mergeClassNames('continue', { loading: inDelete })}
          >
            <Spinner color="#fff" className="spinner" />
            <span>Continuar</span>
            <FaLongArrowAltRight />
          </Button>
        </DeleteModal>

        {loading ? (
          <Spinner size="30px" />
        ) : (
          <>
            <Options>
              <button type="button" onClick={handleEdit}>
                <TiPencil />
              </button>

              <button type="button" onClick={openDeleteModal}>
                <FiTrash2 />
              </button>

              <button type="button">
                <FaShoppingCart />
              </button>
            </Options>

            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              schema={customerEditSchema}
              initialData={customerData}
              className={mergeClassNames({ editable })}
            >
              <CEPManager>
                <Box>
                  <InputEdit
                    icon={BiUserCircle}
                    name="full_name"
                    label="Nome Cliente / Nome Empresa"
                    placeholder="Nome Cliente / Nome Empresa"
                  />

                  <InputEdit
                    icon={BiUserCircle}
                    name="company"
                    label="Razão Social"
                    placeholder="Razão Social"
                  />

                  <InputEdit
                    icon={AiOutlineMail}
                    name="email"
                    label="E-mail"
                    placeholder="E-mail"
                  />
                  <InputEdit
                    icon={FiPhone}
                    name="phone"
                    label="Telefone"
                    mask="(99) \9 9999-9999"
                    placeholder="Telefone"
                  />
                  <InputEdit
                    {...documentInputProps}
                    icon={AiOutlineIdcard}
                    name="document"
                    label="CPF / CNPJ"
                    placeholder="CPF / CNPJ"
                  />
                </Box>

                <Box>
                  <Scope path="address">
                    <InputEdit
                      icon={BiMapAlt}
                      name="zip_code"
                      label="CEP"
                      placeholder="CEP"
                    />
                    <InputEdit
                      icon={BiDirections}
                      name="street"
                      label="Rua"
                      placeholder="Rua"
                    />

                    <ScopedInput size={['50%']}>
                      <InputEdit
                        type="number"
                        icon={AiOutlineFieldNumber}
                        name="number"
                        label="Número"
                        placeholder="Número"
                      />
                      <InputEdit
                        icon={BiStreetView}
                        name="neighborhood"
                        label="Bairro"
                        placeholder="Bairro"
                      />
                    </ScopedInput>

                    <ScopedInput>
                      <InputEdit
                        icon={MdLocationCity}
                        name="city"
                        label="Cidade"
                        placeholder="Cidade"
                      />
                      <InputEdit
                        icon={GoLocation}
                        name="state"
                        label="Estado"
                        placeholder="Estado"
                      />
                    </ScopedInput>
                  </Scope>
                </Box>

                {editable && <SaveButton />}
              </CEPManager>
            </Form>
          </>
        )}
      </Container>
    );
  },
  {
    layout: BackHeaderLayout,
    cases: [Logged],
  },
);
