import styled from '@emotion/styled';

import { BaseCardButton } from '@screen/components/ui/BaseCardButton';

export const Container = styled(BaseCardButton)`
  h3 {
    font-size: 1.9rem;
  }

  h3 {
    font-weight: 400;
  }
`;

export const CustomerInfo = styled.div`
  display: flex;
  margin-top: 4px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    h4 {
      padding-left: 5px;
      font-weight: 400;
      font-size: 1.4rem;
      color: var(--color-grey);
    }

    svg {
      color: var(--color-favorite);
    }
  }
`;
