import styled from '@emotion/styled';

import { BaseCardButton } from '@screen/components/ui/BaseCardButton';

const STATUS_SIZE = '12px';

export const Container = styled(BaseCardButton)`
  h3,
  h4 {
    font-size: 1.8rem;
  }

  h3 {
    font-weight: 400;

    overflow: hidden;
    text-overflow: ellipsis;
  }

  h4 {
    display: flex;
    margin-top: 4px;
    font-weight: 300;

    align-items: center;

    span {
      margin-left: 4px;
    }
  }

  .wrap {
    display: flex;
    align-items: center;
  }

  .info {
    overflow: hidden;
  }

  .status {
    display: block;
    margin-right: ${STATUS_SIZE};

    width: ${STATUS_SIZE};
    height: ${STATUS_SIZE};

    flex-shrink: 0;

    border-radius: 50%;
    background-color: var(--color-light-green);

    &.pending {
      background-color: var(--color-light-red);
    }
  }
`;
