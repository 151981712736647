import { useAsync, mergeClassNames } from '@hitechline/reactools';
import { useContext, useEffect, useRef, useCallback, useState } from 'react';
import { BiRename, BiBarcode } from 'react-icons/bi';
import { /* FaShoppingCart, */ FaLongArrowAltRight } from 'react-icons/fa';
import { FiTrash2, FiX } from 'react-icons/fi';
import { IoMdPricetags } from 'react-icons/io';
import { TiPencil } from 'react-icons/ti';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Container, Options, Box, Form, DeleteModal } from './styles';

import { Logged } from '@/resources/cases/Logged';
import {
  BackHeaderLayout,
  BackHeaderLayoutContext,
} from '@/screen/layouts/BackHeaderLayout';
import { api } from '@modules/services/api';
import { apply } from '@resources/cases/apply';
import { productEditSchema } from '@resources/schemas/product';
import type { FormHandles, FormSubmit } from '@screen/components/forward/Form';
import type { ModalHandles } from '@screen/components/forward/Modal';
import { Button } from '@screen/components/ui/Button';
import { InputEdit } from '@screen/components/ui/InputEdit';
import { SaveButton } from '@screen/components/ui/SaveButton';
import { Spinner } from '@screen/components/ui/Spinner';

export const ProductEdit = apply(
  (): JSX.Element => {
    const history = useHistory();
    const formRef = useRef<FormHandles>(null);
    const modalRef = useRef<ModalHandles>(null);

    const [editable, updateEditable] = useState(false);
    const [inDelete, updateInDelete] = useState(false);

    const { id } = useParams<{ id: string }>();
    const { setTitle } = useContext(BackHeaderLayoutContext);

    const handleEdit = useCallback(() => {
      updateEditable(oldValue => !oldValue);
    }, []);

    const closeDeleteModal = useCallback(() => {
      modalRef.current?.close();
    }, []);

    const openDeleteModal = useCallback(() => {
      modalRef.current?.open();
    }, []);

    const getProductData = useCallback(
      () =>
        api
          .get<ProductHealthyDocument>(`products/${id}`)
          .then(({ data }) => data),
      [id],
    );

    const {
      error,
      loading,
      data: productData,
    } = useAsync(() =>
      api
        .get<ProductHealthyDocument>(`products/${id}`)
        .then(({ data }) => data),
    );

    const handleSubmit: FormSubmit<any> = useCallback(
      async data => {
        await api.put<ProductHealthyDocument>(`products/${id}`, data);

        updateEditable(false);
        toast.success(`${productData?.name} atualizado com sucesso`);

        const newProductData = await getProductData();

        formRef.current?.getUnformRef().current?.setData(newProductData);
      },
      [id, productData, getProductData],
    );

    const handleDelete = useCallback(async () => {
      updateInDelete(true);

      try {
        await api.delete<ProductHealthyDocument>(`products/${id}`);
        history.push('/products');
      } finally {
        closeDeleteModal();
        updateInDelete(false);
      }
    }, [id, history, closeDeleteModal]);

    useEffect(() => {
      if (error) {
        history.push('/products');
      }
    }, [error, history]);

    useEffect(() => {
      setTitle('Detalhes do produto');
    }, [setTitle]);

    return (
      <Container>
        <DeleteModal ref={modalRef}>
          <button type="button" className="close" onClick={closeDeleteModal}>
            <FiX />
          </button>

          <p>
            Deseja continuar e deletar o produto?{' '}
            <strong>{productData?.name}</strong>?
          </p>

          <Button
            onClick={handleDelete}
            className={mergeClassNames('continue', { loading: inDelete })}
          >
            <Spinner color="#fff" className="spinner" />
            <span>Continuar</span>
            <FaLongArrowAltRight />
          </Button>
        </DeleteModal>

        {loading ? (
          <Spinner size="30px" />
        ) : (
          <>
            <Options>
              <button type="button" onClick={handleEdit}>
                <TiPencil />
              </button>

              <button type="button" onClick={openDeleteModal}>
                <FiTrash2 />
              </button>

              {/* <button type="button">
                <FaShoppingCart />
              </button> */}
            </Options>

            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              schema={productEditSchema}
              initialData={productData}
              className={mergeClassNames({ editable })}
            >
              <Box>
                <InputEdit
                  icon={BiRename}
                  name="name"
                  label="Nome do produto"
                  placeholder="Nome do produto"
                />
                <InputEdit
                  icon={BiBarcode}
                  name="sku_code"
                  placeholder="Código SKU"
                  label="Código SKU"
                />
                <InputEdit
                  icon={IoMdPricetags}
                  name="sale_price"
                  pattern="[0-9.]{0,}"
                  label="Preço de venda"
                  placeholder="Preço de venda"
                />
                <InputEdit
                  icon={IoMdPricetags}
                  name="cost_price"
                  pattern="[0-9.]{0,}"
                  label="Preço de custo"
                  placeholder="Preço de custo"
                />
              </Box>

              {editable && <SaveButton />}
            </Form>
          </>
        )}
      </Container>
    );
  },
  {
    layout: BackHeaderLayout,
    cases: [Logged],
  },
);
