import { useRef, useCallback, Fragment } from 'react';
import { MdDelete, MdInfo } from 'react-icons/md';

import type { CartData } from '../../types';
import { DiscountModal } from './DiscountModal';
import { Container } from './styles';

import { getDiscountValue } from '@modules/utils/getDiscountValue';
import type { ModalHandles } from '@screen/components/forward/Modal';

interface Props {
  cart: CartData[];
  remove(id: string): void;
  addDiscount(id: string, discount: SaleDiscountType | undefined): void;
}

export const TransactionTable = ({
  cart,
  remove,
  addDiscount,
}: Props): JSX.Element => {
  const modalRef = useRef<ModalHandles>(null);

  const openDiscountModal = useCallback(() => {
    modalRef.current?.open();
  }, []);

  return (
    <Container>
      <thead>
        <tr>
          <th>Produto</th>
          <th>Qtd.</th>
          <th>Unit.</th>
          <th>Des. Produto</th>
          <th>Total S/Desconto</th>
          <th>Total C/Desconto</th>
          <th />
        </tr>
      </thead>

      <tbody>
        {cart.map(({ quantity, product, discount }) => (
          <Fragment key={product._id}>
            <DiscountModal
              ref={modalRef}
              discount={discount}
              product={product}
              addDiscount={addDiscount}
            />

            <tr>
              <td>{product.name}</td>
              <td>{quantity}x</td>
              <td>
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(product.sale_price)}
              </td>
              <td className="discount-prod">
                -{' '}
                {discount &&
                  Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(
                    getDiscountValue(product.sale_price * quantity, discount),
                  )}
              </td>
              <td>
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(product.sale_price * quantity)}
              </td>
              <td>
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(
                  product.sale_price * quantity -
                    (discount
                      ? getDiscountValue(
                          product.sale_price * quantity,
                          discount,
                        )
                      : 0),
                )}
              </td>
              <td className="buttons">
                <button type="button" onClick={() => remove(product._id)}>
                  <MdDelete />
                </button>
                <button type="button" onClick={openDiscountModal}>
                  <MdInfo />
                </button>
              </td>
            </tr>
          </Fragment>
        ))}
      </tbody>
    </Container>
  );
};
