import { useAsync } from '@hitechline/reactools';
import { useContext, useEffect, useRef, useCallback } from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { BiUserCircle } from 'react-icons/bi';
import { FaShoppingCart } from 'react-icons/fa';
import { FiTrash2, FiPhone } from 'react-icons/fi';
import { TiPencil } from 'react-icons/ti';
import { useParams } from 'react-router-dom';

import { Container, Options, Box } from './styles';

import { Logged } from '@/resources/cases/Logged';
import {
  BackHeaderLayout,
  BackHeaderLayoutContext,
} from '@/screen/layouts/BackHeaderLayout';
import { api } from '@modules/services/api';
import { apply } from '@resources/cases/apply';
import type { FormSubmit, FormHandles } from '@screen/components/forward/Form';
import { InputEdit } from '@screen/components/ui/InputEdit';
import { Spinner } from '@screen/components/ui/Spinner';

export const EmployeeEdit = apply(
  (): JSX.Element => {
    const formRef = useRef<FormHandles>(null);

    const { id } = useParams<{ id: string }>();
    const { setTitle } = useContext(BackHeaderLayoutContext);

    const { loading, data: customerData } = useAsync(() =>
      api
        .get<EmployeeHealthyDocument>(`employee/${id}`)
        .then(({ data }) => data),
    );

    const handleSubmit: FormSubmit<any> = useCallback(() => {
      // Not Implementd
    }, []);

    useEffect(() => {
      setTitle('Aletar/Visualizar');
    }, [setTitle]);

    return (
      <Container
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={customerData}
      >
        {loading ? (
          <Spinner size="30px" />
        ) : (
          <>
            <Options>
              <button type="button">
                <TiPencil />
              </button>

              <button type="button">
                <FiTrash2 />
              </button>

              <button type="button">
                <FaShoppingCart />
              </button>
            </Options>

            <Box>
              <InputEdit
                icon={BiUserCircle}
                name="full_name"
                label="Nome do usuário"
                placeholder="Nome do usuário"
              />

              <InputEdit
                icon={AiOutlineMail}
                name="email"
                label="E-mail"
                placeholder="E-mail"
              />
              <InputEdit
                icon={FiPhone}
                name="phone"
                label="Telefone"
                placeholder="Telefone"
              />
            </Box>
          </>
        )}
      </Container>
    );
  },
  {
    layout: BackHeaderLayout,
    cases: [Logged],
  },
);
