import {
  HOUSE_KIND_TYPE,
  MARITAL_STATUS_TYPE,
  HouseKindType,
  MaritalStatusType,
} from '@word/constants';
import { string, object, number } from 'yup';

export const sellerCreateSchema = object().shape({
  college: string(),
  house_phone: string(),
  phone: string().required('Informe um número de telefone'),
  document: string().required('Informe o CPF do vendedor'),
  full_name: string().required('Informe o nome do vendedor'),
  email: string()
    .email('Insira um e-mail válido')
    .required('Informe o endereço de e-mail'),
  house_kind: number()
    .required()
    .equals<HouseKindType>(HOUSE_KIND_TYPE, 'Algo de errado não está certo'),
  marital_status: number()
    .required()
    .equals<MaritalStatusType>(
      MARITAL_STATUS_TYPE,
      'Algo de errado não está certo',
    ),
  address: object().shape({
    complement: string(),
    street: string().required('Informe a Rua'),
    city: string().required('Informe a Cidade'),
    state: string().required('Informe o Estado'),
    neighborhood: string().required('Informe o Bairro'),
    zip_code: string().required('Informe o CEP da residência'),
    number: number()
      .required('Informe o Número Residencial')
      .default(0)
      .transform((currentValue, originalValue) => {
        if (typeof originalValue === 'string' && originalValue === '') {
          return undefined;
        }

        return currentValue;
      }),
  }),
});

export const sellerEditSchema = object().shape({
  college: string(),
  house_phone: string(),
  phone: string().required('Informe um número de telefone'),
  document: string().required('Informe o CPF do vendedor'),
  full_name: string().required('Informe o nome do vendedor'),
  email: string()
    .email('Insira um e-mail válido')
    .required('Informe o endereço de e-mail'),
  house_kind: number()
    .required()
    .equals<HouseKindType>(HOUSE_KIND_TYPE, 'Algo de errado não está certo'),
  marital_status: number()
    .required()
    .equals<MaritalStatusType>(
      MARITAL_STATUS_TYPE,
      'Algo de errado não está certo',
    ),
  address: object().shape({
    complement: string(),
    street: string().required('Informe a Rua'),
    city: string().required('Informe a Cidade'),
    state: string().required('Informe o Estado'),
    neighborhood: string().required('Informe o Bairro'),
    zip_code: string().required('Informe o CEP da residência'),
    number: number()
      .required('Informe o Número Residencial')
      .default(0)
      .transform((currentValue, originalValue) => {
        if (typeof originalValue === 'string' && originalValue === '') {
          return undefined;
        }

        return currentValue;
      }),
  }),
});
