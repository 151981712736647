import { useField } from '@unform/core';
import { useRef, useEffect } from 'react';

import { Container } from './styles';

export const PaymentMethod = (): JSX.Element => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { registerField } = useField('payment_text');

  useEffect(() => {
    registerField({
      name: 'payment_text',
      ref: textareaRef,
      path: 'current.value',
    });
  }, [registerField]);

  return (
    <Container>
      <textarea
        ref={textareaRef}
        placeholder="Faça uma breve descrição sobre a forma de pagamento."
      />
    </Container>
  );
};
