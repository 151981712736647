import type { FunctionComponent, NamedExoticComponent } from 'react';

interface ApplyData {
  layout?: FunctionComponent;
  cases?: FunctionComponent[];
}

export function apply(
  Component: FunctionComponent,
  { layout: Layout, cases = [] }: ApplyData,
): NamedExoticComponent {
  const Applyed = (): any => {
    if (Layout) {
      return (
        <Layout>
          {Array.from({ length: cases.length }, (_, i) => i)
            .reverse()
            .reduce((children, index) => {
              const Case = cases[index];

              return <Case>{children}</Case>;
            }, <Component />)}
        </Layout>
      );
    }

    return Array.from({ length: cases.length }, (_, i) => i)
      .reverse()
      .reduce((children, index) => {
        const Case = cases[index];

        return <Case>{children}</Case>;
      }, <Component />);
  };

  return Applyed as any;
}
