import {
  useEffect,
  useContext,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { BsCheck } from 'react-icons/bs';
import { FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Association } from './components/Association';
import { PaymentMethod } from './components/PaymentMethod';
import { SubTotal } from './components/SubTotal';
import { TransactionTable } from './components/TransactionTable';
import {
  Container,
  AddProductForm,
  ProductsContainer,
  TotalPrice,
  FinishSaleContainer,
} from './styles';
import type { CartData, FinishFormData, AddProductFormData } from './types';

import { api } from '@modules/services/api';
import { getDiscountValue } from '@modules/utils/getDiscountValue';
import { apply } from '@resources/cases/apply';
import { Logged } from '@resources/cases/Logged';
import { addProductSaleValidation } from '@resources/schemas/sale';
import { Form } from '@screen/components/forward/Form';
import type { FormSubmit } from '@screen/components/forward/Form';
import type { ModalHandles } from '@screen/components/forward/Modal';
import { Button } from '@screen/components/ui/Button';
import { Input } from '@screen/components/ui/Input';
import {
  SelectWithLiveSearch,
  SelectOption,
} from '@screen/components/ui/SelectWithLiveSearch';
import {
  BackHeaderLayout,
  BackHeaderLayoutContext,
} from '@screen/layouts/BackHeaderLayout';

export const SaleCreate = apply(
  (): JSX.Element => {
    const { setTitle } = useContext(BackHeaderLayoutContext);
    const finishModalRef = useRef<ModalHandles>(null);
    const history = useHistory();

    const [options, updateOptions] = useState<
      SelectOption<ProductHealthyDocument>[]
    >([]);
    const [cart, updateCart] = useState<CartData[]>([]);

    const totalValue = useMemo(
      () =>
        cart.reduce((currentValue, { product, quantity, discount }) => {
          const value = product.sale_price * quantity;
          const discountParsed = discount
            ? getDiscountValue(value, discount)
            : 0;

          return currentValue + (value - discountParsed);
        }, 0),
      [cart],
    );

    const openFinishModal = useCallback(() => {
      if (cart.length) {
        finishModalRef.current?.open();
      }
    }, [cart]);

    const closeFinishModal = useCallback(() => {
      finishModalRef.current?.close();
    }, []);

    const handleAddProductSubmit: FormSubmit<AddProductFormData> = useCallback(
      data => {
        const { product, quantity } = data;

        if (
          cart.some(
            ({ product: currentProduct }) => currentProduct._id === product._id,
          )
        ) {
          toast.error('Este produto já foi adicionado.');
          return;
        }

        updateCart(currentCart => [
          ...currentCart,
          {
            product,
            quantity,
          },
        ]);
      },
      [cart],
    );

    const handleFinishSubmit: FormSubmit<FinishFormData> = useCallback(
      async ({ customer, discount, payment_text, shipping }) => {
        const { data: saleId } = await api.post('sales', {
          customer,
          discount,
          shipping,
          payment_text,
          amount: totalValue,
          payment_methods: [],
          cart: cart.map(
            ({ product, quantity, discount: productDiscount }) => ({
              quantity,
              discount: productDiscount,
              product: product._id,
            }),
          ),
        });

        toast.success('A venda foi criada com sucesso!');
        history.push(`/sales/success/${saleId}`);
      },
      [history, cart, totalValue],
    );

    const handleProductSearch = useCallback(async (search: string) => {
      if (!search) {
        updateOptions([]);
        return;
      }

      const { data: productsData } = await api.get<ProductHealthyDocument[]>(
        `/products/search?search=${search}`,
      );

      updateOptions(
        productsData.map(product => ({
          value: product,
          label: `${product.name}${
            product.sku_code ? ` - ${product.sku_code}` : ''
          } (${product.generated_code})`,
        })),
      );
    }, []);

    const removeProduct = useCallback(
      id => {
        const productCart = cart.find(
          ({ product: currentProduct }) => currentProduct._id === id,
        );

        if (
          productCart &&
          // eslint-disable-next-line no-alert
          window.confirm(
            `Deseja deletar o produto ${productCart.product.name}?`,
          )
        ) {
          updateCart(currentCart =>
            currentCart.filter(
              ({ product: currentProduct }) => currentProduct._id !== id,
            ),
          );
        }
      },
      [cart],
    );

    const addProductDiscount = useCallback(
      (id: string, discount: SaleDiscount | undefined) => {
        const hasProductInCart = cart.some(
          ({ product: currentProduct }) => currentProduct._id === id,
        );

        if (!hasProductInCart) {
          return;
        }

        updateCart(currentCart =>
          currentCart.map(data => {
            const { product } = data;

            if (product._id !== id) {
              return data;
            }

            return {
              ...data,
              discount,
            };
          }),
        );
      },
      [cart],
    );

    useEffect(() => {
      setTitle('Nova venda');
    }, [setTitle]);

    return (
      <>
        <Container>
          <AddProductForm
            onSubmit={handleAddProductSubmit}
            schema={addProductSaleValidation}
          >
            <SelectWithLiveSearch
              name="product"
              title="Produto"
              options={options}
              onSearchChange={handleProductSearch}
            />

            <Input
              type="number"
              pattern="[0-9]{0,}"
              name="quantity"
              placeholder="Qtd."
            />

            <Button type="submit">Adicionar</Button>
          </AddProductForm>

          <ProductsContainer>
            <TransactionTable
              cart={cart}
              remove={removeProduct}
              addDiscount={addProductDiscount}
            />
          </ProductsContainer>

          <TotalPrice>
            <div className="container">
              <button
                type="button"
                className="checkout-button"
                onClick={openFinishModal}
              >
                <BsCheck />
              </button>

              <section className="content main-padding-x">
                <p className="title">Sub Total</p>
                <p className="price">
                  {Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(totalValue)}
                </p>
              </section>
            </div>
          </TotalPrice>
        </Container>

        <FinishSaleContainer ref={finishModalRef}>
          <div className="wrapper">
            <button
              type="button"
              onClick={closeFinishModal}
              className="close-button"
            >
              <FiX />
            </button>

            <Form onSubmit={handleFinishSubmit}>
              <section className="main">
                <Association />
                <PaymentMethod />
              </section>

              <section className="footer">
                <SubTotal totalValue={totalValue} />
              </section>
            </Form>
          </div>
        </FinishSaleContainer>
      </>
    );
  },
  {
    layout: BackHeaderLayout,
    cases: [Logged],
  },
);
