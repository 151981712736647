import { useContext, useEffect, useState, useMemo } from 'react';

import { Container } from './styles';

import { getDiscountValue } from '@modules/utils/getDiscountValue';
import { getShippingValue } from '@modules/utils/getShippingValue';
import { FormContext } from '@screen/components/forward/Form';
import { SubmitButton } from '@screen/components/ui/SubmitButton';

interface Props {
  totalValue: number;
}

interface Discount {
  type: number;
  amount: number;
}

interface Shipping {
  type: number;
  amount: number;
}

export const SubTotal = ({ totalValue }: Props): JSX.Element => {
  const { getUnformRef } = useContext(FormContext);

  const [discount, updateDiscount] = useState<Discount | null>(null);
  const [shipping, updateShipping] = useState<Shipping | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const formRef = getUnformRef();

      updateDiscount(formRef.current?.getFieldValue('discount'));
      updateShipping(formRef.current?.getFieldValue('shipping'));
    }, 1000 * 1);

    return () => {
      clearInterval(interval);
    };
  }, [getUnformRef]);

  const discountValue = useMemo<number | null>(() => {
    if (!discount) {
      return null;
    }

    return getDiscountValue(totalValue, discount);
  }, [discount, totalValue]);

  const shippingValue = useMemo<number | null>(() => {
    if (!shipping) {
      return null;
    }

    return getShippingValue(totalValue, shipping);
  }, [shipping, totalValue]);

  return (
    <Container>
      <p>TOTAL</p>

      <p className="main-price">
        {Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(totalValue)}
      </p>

      {discountValue ? (
        <p className="discount-aplicated">
          -{' '}
          <span>
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(discountValue)}
          </span>
        </p>
      ) : null}

      {shippingValue ? (
        <p className="shipping-aplicated">
          +{' '}
          <span>
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(shippingValue)}
          </span>
        </p>
      ) : null}

      {discountValue || shippingValue ? (
        <>
          <div className="divider" />

          <p className="main-price">
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(totalValue - (discountValue ?? 0) + (shippingValue ?? 0))}
          </p>
        </>
      ) : null}

      <SubmitButton className="button" type="submit" text="Finalizar" />
    </Container>
  );
};
