import { useRef, useCallback } from 'react';
import { CgMenuLeft } from 'react-icons/cg';
import { useSelector } from 'react-redux';

import { Drawer, Handles as DrawerHandles } from './fragments/Drawer';
import { Container, BrandName } from './styles';

export const Header = (): JSX.Element => {
  const drawerRef = useRef<DrawerHandles>(null);
  const { signed } = useSelector(({ auth }) => auth);

  const openDrawer = useCallback(() => {
    drawerRef.current?.open();
  }, []);

  if (signed) {
    return (
      <>
        <Drawer ref={drawerRef} />

        <Container className="main-padding-x">
          <button type="button" onClick={openDrawer}>
            <CgMenuLeft size="4rem" />
          </button>

          <BrandName>WORKUP</BrandName>
        </Container>
      </>
    );
  }

  return (
    <Container className="main-padding-x">
      <BrandName>WORKUP</BrandName>
    </Container>
  );
};
