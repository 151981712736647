import { mergeClassNames } from '@hitechline/reactools';
import { ImBarcode } from 'react-icons/im';
import { Link } from 'react-router-dom';

import { Container } from './styles';

interface Props {
  href: string;
  name: string;
  code: string;
  pending: boolean;
}

export const Card = ({ href, name, code, pending }: Props): JSX.Element => (
  <Container as={Link} to={href}>
    <div className="wrap">
      <span
        className={mergeClassNames('status', {
          pending,
        })}
      />

      <section className="info">
        <h3>{name}</h3>
        <h4>
          <ImBarcode />
          <span>{code}</span>
        </h4>
      </section>
    </div>
  </Container>
);
