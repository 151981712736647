import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Form } from '@screen/components/forward/Form';

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;

  gap: 20px;
  align-items: center;
`;

const INPUT_SPACING = '14px';

export const Box = styled.section`
  --wai-max-width: 100%;

  display: grid;
  padding: 15px;
  width: 100%;

  gap: ${INPUT_SPACING};
  grid-template-columns: repeat(2, 1fr);

  border-radius: 6px;
  box-shadow: var(--main-bottom-box-shadow);
  background-color: var(--color-white);

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

interface ScopedInput {
  size?: string[];
}

export const ScopedInput = styled.section<ScopedInput>`
  display: flex;
  width: 100%;

  gap: ${INPUT_SPACING};

  ${({ size = [] }) =>
    size.map(
      (value, index) => css`
        > div:nth-child(${index + 1}) {
          width: ${value};
        }
      `,
    )}
`;
