import { mergeRefs, mergeClassNames } from '@hitechline/reactools';
import type { DiscountKindType } from '@word/constants';
import { forwardRef, useCallback, useRef, Ref } from 'react';
import { FiX, FiCheck, FiDollarSign, FiPercent } from 'react-icons/fi';

import { Container, DiscountManager } from './styles';

import type { ModalHandles } from '@screen/components/forward/Modal';

interface Props {
  product: ProductHealthyDocument;
  discount?: SaleDiscountType;
  addDiscount(id: string, discount: SaleDiscountType | undefined): void;
}

export const DiscountModal = forwardRef(
  (
    { product, discount, addDiscount }: Props,
    ref: Ref<ModalHandles>,
  ): JSX.Element => {
    const modalRef = useRef<ModalHandles>(null);
    const discountValueInputRef = useRef<HTMLInputElement>(null);

    const closeDiscountModal = useCallback(() => {
      modalRef.current?.close();
    }, []);

    //

    const handleDiscount = useCallback(() => {
      if (discount) {
        addDiscount(product._id, undefined);
        return;
      }

      const value = discountValueInputRef.current?.value;

      addDiscount(product._id, {
        type: 0,
        amount: value ? Number(value) : 0,
      });
    }, [product._id, discount, addDiscount]);

    const updateDiscountValue = useCallback(() => {
      if (!discount) {
        return;
      }

      const value = discountValueInputRef.current?.value;

      addDiscount(product._id, {
        ...discount,
        amount: value ? Number(value) : discount?.amount ?? 0,
      });
    }, [product._id, discount, addDiscount]);

    const setDiscountType = useCallback(
      (type: DiscountKindType) => {
        addDiscount(product._id, {
          amount: 0,
          ...discount,
          type,
        });
      },
      [product._id, discount, addDiscount],
    );

    return (
      <Container
        ref={mergeRefs([ref, modalRef])}
        className={mergeClassNames({
          active: !!discount,
        })}
      >
        <button
          type="button"
          onClick={closeDiscountModal}
          className="close-button"
        >
          <FiX />
        </button>

        <section className="main">
          <div className="header">
            <button type="button" onClick={handleDiscount}>
              <FiCheck />
            </button>

            <span>Adicionar Desconto</span>
          </div>

          <div className="content">
            <DiscountManager>
              <button
                type="button"
                onClick={() => setDiscountType(0)}
                className={mergeClassNames({
                  selected: (discount?.type ?? 0) === 0,
                })}
              >
                <FiDollarSign />
              </button>

              <button
                type="button"
                onClick={() => setDiscountType(1)}
                className={mergeClassNames({
                  selected: discount?.type === 1,
                })}
              >
                <FiPercent />
              </button>

              <input
                defaultValue={discount?.amount ?? 0}
                onChange={updateDiscountValue}
                ref={discountValueInputRef}
              />
            </DiscountManager>
          </div>
        </section>
      </Container>
    );
  },
);

DiscountModal.displayName = 'DiscountModal';
