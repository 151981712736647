import styled from '@emotion/styled';

import { Z_INDEX } from '@screen/styles/config';

const HEADER_Y_SPACING = '12px';

export const Container = styled.header`
  display: flex;
  position: relative;
  height: 66px;
  z-index: ${Z_INDEX.MAIN};

  padding-top: ${HEADER_Y_SPACING};
  padding-bottom: ${HEADER_Y_SPACING};

  align-items: center;
  justify-content: space-between;

  color: var(--color-white);
  background-color: var(--color-favorite);
`;

export const BrandName = styled.h1`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;
