import styled from '@emotion/styled';
import { cssVar, rgba } from 'polished';

import { Modal } from '@screen/components/forward/Modal';

export const Container = styled.div`
  --padding: 20px;

  box-shadow: 0px 8px 44px 0 rgba(0, 0, 0, 0.14);
  border-top: 4px solid var(--color-favorite);
  border-radius: var(--main-bigger-border-radius);
`;

export const InfoContainer = styled.section`
  display: flex;
  justify-content: space-between;

  padding: var(--padding);

  > div {
    h4 {
      font-size: 2rem;
    }

    & + div {
      margin-top: 8px;
    }
  }

  button {
    svg {
      color: var(--color-light-red);
    }
  }
`;

export const ProductsContainer = styled.section`
  .item {
    display: flex;
    padding: var(--padding);

    align-items: center;
    justify-content: space-between;

    font-weight: 600;

    color: var(--color-strong-grey);
    background-color: var(--color-teen-grey);

    & + .item {
      border-top: 1px solid var(--color-white-grey);
    }

    .name {
      display: flex;
      margin-right: 16px;

      .quantity {
        margin: auto 0 0 6px;

        font-size: 90%;
        color: var(--color-strong-blue);
      }
    }
  }
`;

export const OptionsContainer = styled.section`
  display: flex;
  padding: var(--padding);

  gap: 12px;

  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  background-color: var(--color-white-grey);

  .button {
    display: flex;
    padding: 8px 12px;
    height: 46px;

    align-items: center;
    transition: all 200ms;

    &.pdf {
      border: 2px solid var(--color-weak-black);

      &:hover {
        color: var(--color-white);
        background-color: var(--color-weak-black);
      }
    }

    &.create {
      color: var(--color-white);
      background-color: var(--color-weak-black);

      &:hover {
        transform: scale(0.9);
      }
    }
  }
`;

export const FooterContainer = styled.section`
  padding: var(--padding);
  border-top: 1px solid var(--colo-white-grey);

  .date {
    font-size: 1.4rem;
    color: var(--color-grey);
  }
`;

export const DeleteModal = styled(Modal)`
  --wai-max-width: 100%;

  width: 100%;
  max-width: max-content;

  padding: 20px;
  box-shadow: var(--main-bottom-box-shadow);

  background-color: var(--color-white);
  border-radius: var(--main-bigger-border-radius);

  p {
    text-align: center;
  }

  button.close {
    display: flex;
    width: 30px;
    height: 30px;
    margin-bottom: 20px;

    align-items: center;
    justify-content: center;

    border-radius: 4px;
    border: 2px solid var(--color-grey);

    &:hover {
      background-color: ${rgba(cssVar('--color-black', '#000'), 0.1)};
    }

    svg {
      width: 18px;
      height: auto;

      color: var(--color-grey);
    }
  }

  button.continue {
    display: flex;
    margin-top: 20px;

    align-items: center;
    justify-content: space-between;

    padding: 0 16px;
    background-color: var(--color-light-red);

    .spinner {
      width: max-content;
    }

    .spinner {
      display: none;
    }

    &.loading {
      cursor: not-allowed;

      &,
      &:hover {
        filter: brightness(1.5);
      }

      span {
        display: none;
      }

      .spinner {
        display: block;
      }
    }
  }

  @media (max-width: 500px) {
    display: flex;
    height: 100%;

    flex-direction: column;
    justify-content: space-between;

    max-width: 100%;
    border-radius: 0;

    p {
      font-size: 2rem;
    }
  }
`;
