import { useRef, useCallback, useMemo, useEffect } from 'react';
// import { AiOutlineWarning } from 'react-icons/ai';
import { FiCamera } from 'react-icons/fi';

import {
  Container,
  // WarningModal,
  UserContainer,
  AvatarContainer,
  ButtonsContainer,
  Button,
} from './styles';

import { Logged } from '@resources/cases/Logged';
import { navigation } from '@resources/data/navigation';
import { useAuth } from '@resources/hooks/useAuth';
import { useUI } from '@resources/hooks/useUI';
import type { ModalHandles } from '@screen/components/forward/Modal';
import { Avatar } from '@screen/components/ui/Avatar';
import { DefaultLayout } from '@screen/layouts/DefaultLayout';

export const Home = (): JSX.Element => {
  const modalRef = useRef<ModalHandles>(null);
  const avatarInputRef = useRef<HTMLInputElement>(null);

  const { user } = useAuth();
  const { hasOpenedWarnModal, setHasOpenedWarnModal } = useUI();

  const links = useMemo(
    () =>
      navigation
        .filter(({ permission }) => (user.perm ?? -1) >= permission)
        .map(({ href, title, icon: Icon }) => (
          <Button key={title} to={href} className="break-word">
            <Icon size="2.8rem" />

            <span>{title}</span>
          </Button>
        )),
    [user.perm],
  );

  const handleAvatarButtonClick = useCallback(() => {
    avatarInputRef.current?.click();
  }, []);

  // const closeModal = useCallback(() => {
  //   modalRef.current?.close();
  // }, []);

  // const handleCloseModal = useCallback(() => {
  //   setHasOpenedWarnModal();
  // }, [setHasOpenedWarnModal]);

  useEffect(() => {
    if (!hasOpenedWarnModal) {
      modalRef.current?.open();
    }
  }, [hasOpenedWarnModal, setHasOpenedWarnModal]);

  return (
    <Logged>
      <DefaultLayout>
        {/* {!hasOpenedWarnModal && (
          <WarningModal ref={modalRef} onClose={handleCloseModal}>
            <button type="button" className="close" onClick={closeModal}>
              <FiX />
            </button>

            <div className="warning-icon">
              <AiOutlineWarning size="200px" />
            </div>

            <h1>VERSÃO BETA!</h1>
            <p>
              Atenção usuários, o sistema está em sua versão beta. Portanto
              todas as funcionalidades poderão ter instabilidade e outras
              estarão desativadas. Qualquer dúvida entrar em contato com a
              Hitechline e aguarde novas atualizações
            </p>
          </WarningModal>
        )} */}

        <Container className="main-container">
          <UserContainer>
            <AvatarContainer>
              <input type="file" ref={avatarInputRef} />
              <Avatar
                size="130px"
                name={user.full_name}
                src={(user as any).avatar}
                round
              />

              <button type="button" onClick={handleAvatarButtonClick}>
                <FiCamera size="20px" color="var(--color-white)" />
              </button>
            </AvatarContainer>

            <h2>{user.full_name}</h2>
          </UserContainer>

          <ButtonsContainer>{links}</ButtonsContainer>
        </Container>
      </DefaultLayout>
    </Logged>
  );
};
