import styled from '@emotion/styled';
import { rgba, cssVar } from 'polished';

import { BaseCardButton } from '@screen/components/ui/BaseCardButton';

const BOX_SPACING = '10px';
const STICKER_SIZE = '12px';
const SELLER_AVATAR_SIZE = '30px';

export const Container = styled(BaseCardButton)``;

export const InfoContainer = styled.div`
  display: flex;

  h3 {
    font-weight: 400;
    font-size: 1.8rem;
  }

  h4 {
    margin-top: 4px;
  }

  section {
    margin-left: ${BOX_SPACING};
  }

  .sticker {
    width: ${SELLER_AVATAR_SIZE};

    padding-top: 6px;
    padding-left: calc((${SELLER_AVATAR_SIZE} / 2) - (${STICKER_SIZE} / 2));

    span {
      display: block;
      margin-right: ${STICKER_SIZE};

      width: ${STICKER_SIZE};
      height: ${STICKER_SIZE};

      border-radius: 50%;
      background-color: var(--color-favorite);
    }
  }
`;

export const SellerContainer = styled.div`
  display: flex;
  margin-top: 25px;
  align-items: center;

  img {
    object-fit: cover;

    width: ${SELLER_AVATAR_SIZE};
    height: ${SELLER_AVATAR_SIZE};

    border-radius: 50%;
  }

  h5 {
    font-weight: 300;
    color: ${rgba(cssVar('--color-black', '#000'), 0.5)};
  }

  section {
    margin-left: ${BOX_SPACING};
  }
`;

export const CustomerContainer = styled.div`
  display: flex;
  margin-top: 25px;
  align-items: center;

  h5 {
    font-weight: 300;
    color: ${rgba(cssVar('--color-black', '#000'), 0.5)};
  }

  h4 {
    color: var(--color-favorite);
  }

  img {
    object-fit: cover;

    width: ${SELLER_AVATAR_SIZE};
    height: ${SELLER_AVATAR_SIZE};

    border-radius: 50%;
  }

  section {
    margin-left: ${BOX_SPACING};
  }
`;
