import { useAsync } from '@hitechline/reactools';
import { useEffect, useContext, useCallback, useState } from 'react';
import { FiPlus /* , FiSliders */ } from 'react-icons/fi';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';

import { Card } from './components/Card';
import { Container, NoMoreItems } from './styles';

import { api } from '@modules/services/api';
import { apply } from '@resources/cases/apply';
import { Logged } from '@resources/cases/Logged';
import { HeroSearchContext } from '@screen/components/ui/HeroSearch';
import { Spinner } from '@screen/components/ui/Spinner';
import {
  TopSearchLayout,
  TopSearchLayoutContext,
} from '@screen/layouts/TopSearchLayout';

interface ListAPIData {
  count: number;
  inPage: number;
  itemsInPage: number;
  itemsPerPage: number;
  pages: number;
  items: ProductHealthyDocument[];
}

export const Products = apply(
  (): JSX.Element => {
    const history = useHistory();

    const { search } = useContext(HeroSearchContext);
    const { configure } = useContext(TopSearchLayoutContext);

    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [inReset, setInReset] = useState(false);
    const [products, setProducts] = useState<ProductHealthyDocument[]>([]);

    const goToCreate = useCallback(() => {
      history.push('/products/create');
    }, [history]);

    const addMoreProducts = useCallback(
      (moreProducts: ProductHealthyDocument[]) => {
        setProducts(currentProducts => [...currentProducts, ...moreProducts]);
      },
      [],
    );

    const { loading } = useAsync(async () => {
      const { data: apiData } = await api.get<ListAPIData>('products/all');

      setPages(apiData.pages);
      addMoreProducts(apiData.items);
    });

    const fetchMoreProducts = useCallback(async () => {
      if (currentPage === pages) {
        return;
      }

      const getPage = currentPage + 1;
      const {
        data: { items },
      } = await api.get<ListAPIData>(
        `products/all?page=${getPage}&search=${search}`,
      );

      setCurrentPage(getPage);
      addMoreProducts(items);
    }, [search, pages, currentPage, addMoreProducts]);

    const reset = useCallback(async () => {
      try {
        setInReset(true);
        setCurrentPage(1);
        setProducts([]);

        const { data: apiData } = await api.get<ListAPIData>(
          `products/all?search=${search}`,
        );

        setPages(apiData.pages);
        addMoreProducts(apiData.items);
      } finally {
        setInReset(false);
      }
    }, [search, addMoreProducts]);

    useEffect(() => {
      if (loading) {
        return;
      }

      reset();
    }, [loading, reset]);

    useEffect(() => {
      configure({
        title: 'Produtos',
        placeholder: 'Busque por: Nome Produto | Categoria ',
        buttons: [
          // {
          //   // icon: FiSliders,
          //   // handler: () => {
          //   //   // void
          //   // },
          // },
          {
            icon: FiPlus,
            handler: goToCreate,
          },
        ],
      });
    }, [configure, goToCreate]);

    return (
      <Container>
        {loading || inReset ? (
          <Spinner size="40px" />
        ) : (
          <InfiniteScroll
            dataLength={products.length}
            next={fetchMoreProducts}
            hasMore={currentPage < pages}
            loader={<Spinner size="30px" />}
            className="items"
            endMessage={<NoMoreItems>Isso é tudo :)</NoMoreItems>}
          >
            {products.map(({ _id, name, generated_code }) => (
              <Card
                key={_id}
                name={name}
                code={generated_code}
                href={`/products/${_id}`}
                pending={false}
              />
            ))}
          </InfiniteScroll>
        )}
      </Container>
    );
  },
  {
    layout: TopSearchLayout,
    cases: [Logged],
  },
);
