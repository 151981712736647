import { string, object, number } from 'yup';

export const providerCreateSchema = object().shape({
  state_registration: string(),
  company_name: string().required(),
  responsible_activity: string().required(),
  phone: string().required('Informe o Telefone'),
  document: string().required('Informe o CNPJ'),
  responsible_name: string().required('Nome do Fornecedor'),
  email: string().email().required('Informe um e-mail'),
  industry_activity: string().required('Informe o Ramo de Atividade'),
  address: object()
    .required()
    .shape({
      city: string().required('Informe a Cidade'),
      zip_code: string().required('Informe o CEP'),
      state: string().required('Informe o Estado'),
      street: string().required('Informe o nome da Rua'),
      neighborhood: string().required('Informe o Bairro'),
      complement: string(),
      number: number()
        .required('Informe o Número Residencial')
        .default(0)
        .transform((currentValue, originalValue) => {
          if (typeof originalValue === 'string' && originalValue === '') {
            return undefined;
          }

          return currentValue;
        }),
    }),
});

export const providerEditSchema = object().shape({
  state_registration: string(),
  company_name: string().required(),
  responsible_activity: string().required(),
  phone: string().required('Informe o Telefone'),
  document: string().required('Informe o CNPJ'),
  responsible_name: string().required('Nome do Fornecedor'),
  email: string().email().required('Informe um e-mail'),
  industry_activity: string().required('Informe o Ramo de Atividade'),
  address: object()
    .required()
    .shape({
      city: string().required('Informe a Cidade'),
      zip_code: string().required('Informe o CEP'),
      state: string().required('Informe o Estado'),
      street: string().required('Informe o nome da Rua'),
      neighborhood: string().required('Informe o Bairro'),
      complement: string(),
      number: number()
        .required('Informe o Número Residencial')
        .default(0)
        .transform((currentValue, originalValue) => {
          if (typeof originalValue === 'string' && originalValue === '') {
            return undefined;
          }

          return currentValue;
        }),
    }),
});
