import styled from '@emotion/styled';

const CONTAINER_SPACING = '20%';
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-top: ${CONTAINER_SPACING};

  h1 {
    color: var(--color-favorite);
    font-size: 5rem;
    margin: 10px;
  }
`;
