import { useAsync } from '@hitechline/reactools';
import { useEffect, useContext, useCallback, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';

import { Card } from './components/Card';
import { Container, NoMoreItems } from './styles';

import { api } from '@modules/services/api';
import { apply } from '@resources/cases/apply';
import { Logged } from '@resources/cases/Logged';
import { HeroSearchContext } from '@screen/components/ui/HeroSearch';
import { Spinner } from '@screen/components/ui/Spinner';
import {
  TopSearchLayout,
  TopSearchLayoutContext,
} from '@screen/layouts/TopSearchLayout';

interface ListAPIData {
  count: number;
  inPage: number;
  itemsInPage: number;
  itemsPerPage: number;
  pages: number;
  items: ProviderHealthyDocument[];
}

export const Providers = apply(
  (): JSX.Element => {
    const history = useHistory();

    const { search } = useContext(HeroSearchContext);
    const { configure } = useContext(TopSearchLayoutContext);

    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [inReset, setInReset] = useState(false);
    const [providers, setProviders] = useState<ProviderHealthyDocument[]>([]);

    const goToCreate = useCallback(() => {
      history.push('/providers/create');
    }, [history]);

    const addMoreProviders = useCallback(
      (moreProviders: ProviderHealthyDocument[]) => {
        setProviders(currentProviders => [
          ...currentProviders,
          ...moreProviders,
        ]);
      },
      [],
    );

    const { loading } = useAsync(async () => {
      const { data: apiData } = await api.get<ListAPIData>('providers/all');

      addMoreProviders(apiData.items);
      setPages(apiData.pages);
    });

    const fetchMoreProviders = useCallback(async () => {
      if (currentPage === pages) {
        return;
      }

      const getPage = currentPage + 1;

      const {
        data: { items },
      } = await api.get<ListAPIData>(
        `providers/all?page=${getPage}&search=${search}`,
      );

      setCurrentPage(getPage);
      addMoreProviders(items);
    }, [pages, search, currentPage, addMoreProviders]);

    const reset = useCallback(async () => {
      try {
        setInReset(true);
        setCurrentPage(1);
        setProviders([]);

        const { data: apiData } = await api.get<ListAPIData>(
          `providers/all?search=${search}`,
        );

        setPages(apiData.pages);
        addMoreProviders(apiData.items);
      } finally {
        setInReset(false);
      }
    }, [search, addMoreProviders]);

    useEffect(() => {
      if (loading) {
        return;
      }

      reset();
    }, [loading, reset]);

    useEffect(() => {
      configure({
        title: 'Fornecedores',
        placeholder: 'Busque por: Nome Fornecedor | Razão Social | CPF ou CNPJ',
        buttons: [
          {
            icon: FiPlus,
            handler: goToCreate,
          },
        ],
      });
    }, [configure, goToCreate]);

    return (
      <Container>
        {loading || inReset ? (
          <Spinner size="40px" />
        ) : (
          <InfiniteScroll
            dataLength={providers.length}
            next={fetchMoreProviders}
            hasMore={currentPage < pages}
            loader={<Spinner size="30px" />}
            className="items"
            endMessage={<NoMoreItems>Isso é tudo :)</NoMoreItems>}
          >
            {providers.map(({ _id, document, company_name }) => (
              <Card
                key={_id}
                document={document}
                companyName={company_name}
                href={`/providers/${_id}`}
              />
            ))}
          </InfiniteScroll>
        )}
      </Container>
    );
  },
  {
    layout: TopSearchLayout,
    cases: [Logged],
  },
);
