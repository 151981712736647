import {
  DISCOUNT_KIND_TYPE,
  SHIPPING_KIND_TYPE,
  PAYMENT_METHODS_TYPE,
  ShippingKindType,
  DiscountKindType,
  PaymentMethodsType,
} from '@word/constants';
import { string, number, array, object } from 'yup';

const numberSchema = number().transform((currentValue, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return undefined;
  }

  return currentValue;
});

export const saleCreateValidation = object().shape({
  seller: string(),
  customer: string(),
  payment_text: string(),
  amount: number().required(),
  products: array()
    .required('Você deve inserir ao menos 1 produto')
    .min(1)
    .of(string()),
  discount: object()
    .optional()
    .default(undefined)
    .shape({
      type: number()
        .required('Defina o tipo de desconto')
        .equals<DiscountKindType>(DISCOUNT_KIND_TYPE),
      amount: number().required(),
    }),
  shipping: object()
    .optional()
    .default(undefined)
    .shape({
      type: number()
        .required('Defina o tipo de frete')
        .equals<ShippingKindType>(SHIPPING_KIND_TYPE),
      amount: number().required(),
    }),
  payment_methods: array()
    .required('Defina a forma de pagamento')
    // .min(1)
    .of(number().equals<PaymentMethodsType>(PAYMENT_METHODS_TYPE)),
});

export const addProductSaleValidation = object().shape({
  quantity: numberSchema
    .required('Insira uma quantidade')
    .min(1, 'A quantidade deve ser maior que 1'),
  product: object().required().shape({
    _id: string(),
    category: string(),
    sku_code: string(),
    provider_id: string(),
    cost_price: numberSchema,
    sale_price: numberSchema,
    name: string(),
  }),
});
