import { HTMLAttributes } from 'react';

import { Container, Spinner as SpinnerElement } from './styles';

interface Props extends HTMLAttributes<HTMLDivElement> {
  size?: string;
  color?: string;
}

export const Spinner = ({
  size = '20px',
  color = '#000',
  ...rest
}: Props): JSX.Element => (
  <Container {...rest}>
    <SpinnerElement size={size} color={color} />
  </Container>
);
