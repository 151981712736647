import { mergeClassNames } from '@hitechline/reactools';
import { useContext, ButtonHTMLAttributes } from 'react';

import { Container, Spinner } from './styles';

import { FormContext } from '@screen/components/forward/Form';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

export const SubmitButton = ({
  text,
  className,
  ...props
}: Props): JSX.Element => {
  const { loading } = useContext(FormContext);

  return (
    <Container
      {...props}
      type="submit"
      className={mergeClassNames(className, { loading })}
    >
      <Spinner color="#fff" className="spinner" />

      <span>{text}</span>
    </Container>
  );
};
