import { Route, Switch } from 'react-router-dom';

import { useBuildRoute } from '@resources/hooks/useBuildRoute';
import { Sales } from '@screen/pages/Sales';
import { SaleCreate } from '@screen/pages/Sales/pages/Create';
import { Success } from '@screen/pages/Sales/pages/Success';

export const SaleRoutes = (): JSX.Element => {
  const { make } = useBuildRoute('/sales');

  return (
    <Switch>
      <Route path={make('/')} exact>
        <Sales />
      </Route>

      <Route path={make('/create')}>
        <SaleCreate />
      </Route>

      <Route path={make('/success/:id')}>
        <Success />
      </Route>
    </Switch>
  );
};
