import { Route, Switch } from 'react-router-dom';

import { useBuildRoute } from '@resources/hooks/useBuildRoute';
import { Customers } from '@screen/pages/Customers';
import { CustomerCreate } from '@screen/pages/Customers/pages/Create';
import { CustomerEdit } from '@screen/pages/Customers/pages/Edit';

export const CustomerRoutes = (): JSX.Element => {
  const { make } = useBuildRoute('/customers');

  return (
    <Switch>
      <Route path={make('/')} exact>
        <Customers />
      </Route>

      <Route path={make('/create')}>
        <CustomerCreate />
      </Route>

      <Route path={make('/:id')}>
        <CustomerEdit />
      </Route>
    </Switch>
  );
};
