import { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const Button = ({ children, ...rest }: Props): JSX.Element => (
  <Container type="button" {...rest}>
    {children}
  </Container>
);
