import type { IconType } from 'react-icons';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import {
  FiUser,
  FiHome,
  FiUsers,
  FiUserPlus,
  FiTruck,
  FiBox,
  FiFileText,
  // FiBarChart,
} from 'react-icons/fi';

interface Navigation {
  title: string;
  href: string;
  icon: IconType;
  permission: number;
}

export const navigation: Navigation[] = [
  {
    title: 'Criar venda',
    href: '/sales/create',
    icon: AiOutlineShoppingCart,
    permission: -1,
  },
  {
    title: 'Clientes',
    href: '/customers',
    icon: FiUsers,
    permission: 0,
  },
  {
    title: 'Vendedores',
    href: '/sellers',
    icon: FiUserPlus,
    permission: 0,
  },
  {
    title: 'Fornecedores',
    href: '/providers',
    icon: FiTruck,
    permission: 0,
  },
  {
    title: 'Produtos',
    href: '/products',
    icon: FiBox,
    permission: 0,
  },
];

export const drawerLinks: Navigation[] = [
  {
    title: 'Início',
    href: '/app',
    icon: FiHome,
    permission: -1,
  },
  {
    title: 'Clientes',
    href: '/customers',
    icon: FiUsers,
    permission: 0,
  },
  {
    title: 'Vendedores',
    href: '/sellers',
    icon: FiUserPlus,
    permission: 0,
  },
  {
    title: 'Fornecedores',
    href: '/providers',
    icon: FiTruck,
    permission: 0,
  },
  {
    title: 'Produtos',
    href: '/products',
    icon: FiBox,
    permission: 0,
  },
  {
    title: 'Usuários',
    href: '/employees',
    icon: FiUser,
    permission: 0,
  },
  {
    title: 'Vendas',
    href: '/sales',
    icon: FiFileText,
    permission: -1,
  },
  // {
  //   title: 'Relatórios',
  //   href: '/reports',
  //   icon: FiBarChart,
  //   permission: 0,
  // },
];
