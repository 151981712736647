import { Route, Switch } from 'react-router-dom';

import { useBuildRoute } from '@resources/hooks/useBuildRoute';
import { NotImplemented } from '@screen/pages/NotImplemented';

export const ReportRoutes = (): JSX.Element => {
  const { base } = useBuildRoute('/reports');

  return (
    <Switch>
      <Route path={base}>
        <NotImplemented />
      </Route>
    </Switch>
  );
};
