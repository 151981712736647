import { useAsync, mergeClassNames } from '@hitechline/reactools';
import { Scope } from '@unform/core';
import { useContext, useEffect, useRef, useCallback, useState } from 'react';
import {
  AiOutlineMail,
  AiOutlineIdcard,
  AiOutlineFieldNumber,
} from 'react-icons/ai';
import {
  BiUserCircle,
  BiMapAlt,
  BiDirections,
  BiStreetView,
} from 'react-icons/bi';
import { /* FaShoppingCart */ FaLongArrowAltRight } from 'react-icons/fa';
import { FiTrash2, FiPhone, FiX } from 'react-icons/fi';
import { GoLocation } from 'react-icons/go';
import { MdLocationCity } from 'react-icons/md';
import { TiPencil } from 'react-icons/ti';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Container,
  Options,
  Box,
  ScopedInput,
  Form,
  DeleteModal,
} from './styles';

import { Logged } from '@/resources/cases/Logged';
import { useCPFOrCPNJInput } from '@/resources/hooks/useCPFOrCPNJInput';
import {
  BackHeaderLayout,
  BackHeaderLayoutContext,
} from '@/screen/layouts/BackHeaderLayout';
import { api } from '@modules/services/api';
import { apply } from '@resources/cases/apply';
import { CEPManager } from '@resources/managers/CEPManager';
import { providerEditSchema } from '@resources/schemas/provider';
import type { FormHandles, FormSubmit } from '@screen/components/forward/Form';
import type { ModalHandles } from '@screen/components/forward/Modal';
import { Button } from '@screen/components/ui/Button';
import { InputEdit } from '@screen/components/ui/InputEdit';
import { SaveButton } from '@screen/components/ui/SaveButton';
import { Spinner } from '@screen/components/ui/Spinner';

export const ProviderEdit = apply(
  (): JSX.Element => {
    const history = useHistory();
    const formRef = useRef<FormHandles>(null);
    const modalRef = useRef<ModalHandles>(null);

    const [editable, updateEditable] = useState(false);
    const [inDelete, updateInDelete] = useState(false);

    const { id } = useParams<{ id: string }>();
    const { setTitle } = useContext(BackHeaderLayoutContext);
    const documentInputProps = useCPFOrCPNJInput();

    const handleEdit = useCallback(() => {
      updateEditable(oldValue => !oldValue);
    }, []);

    const closeDeleteModal = useCallback(() => {
      modalRef.current?.close();
    }, []);

    const openDeleteModal = useCallback(() => {
      modalRef.current?.open();
    }, []);

    const getProviderData = useCallback(
      () =>
        api
          .get<ProviderHealthyDocument>(`providers/${id}`)
          .then(({ data }) => data),
      [id],
    );

    const {
      error,
      loading,
      data: providerData,
    } = useAsync(() =>
      api
        .get<ProviderHealthyDocument>(`providers/${id}`)
        .then(({ data }) => data),
    );

    const handleSubmit: FormSubmit<any> = useCallback(
      async data => {
        await api.put<ProviderHealthyDocument>(`providers/${id}`, data);

        updateEditable(false);
        toast.success(`${providerData?.company_name} atualizado com sucesso`);

        const newProviderData = await getProviderData();

        formRef.current?.getUnformRef().current?.setData(newProviderData);
      },
      [id, providerData, getProviderData],
    );

    const handleDelete = useCallback(async () => {
      updateInDelete(true);

      try {
        await api.delete<ProviderHealthyDocument>(`providers/${id}`);
        history.push('/providers');
      } finally {
        closeDeleteModal();
        updateInDelete(false);
      }
    }, [id, history, closeDeleteModal]);

    useEffect(() => {
      if (error) {
        history.push('/providers');
      }
    }, [error, history]);

    useEffect(() => {
      setTitle('Detalhes Fornecedor');
    }, [setTitle]);

    return (
      <Container>
        <DeleteModal ref={modalRef}>
          <button type="button" className="close" onClick={closeDeleteModal}>
            <FiX />
          </button>

          <p>
            Deseja continuar e deletar o fornecedor{' '}
            <strong>{providerData?.company_name}</strong>?
          </p>

          <Button
            onClick={handleDelete}
            className={mergeClassNames('continue', { loading: inDelete })}
          >
            <Spinner color="#fff" className="spinner" />
            <span>Continuar</span>
            <FaLongArrowAltRight />
          </Button>
        </DeleteModal>

        {loading ? (
          <Spinner size="30px" />
        ) : (
          <>
            <Options>
              <button type="button" onClick={handleEdit}>
                <TiPencil />
              </button>

              <button type="button" onClick={openDeleteModal}>
                <FiTrash2 />
              </button>

              {/* <button type="button">
                <FaShoppingCart />
              </button> */}
            </Options>

            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              schema={providerEditSchema}
              initialData={providerData}
              className={mergeClassNames({ editable })}
            >
              <CEPManager>
                <Box>
                  <InputEdit
                    icon={BiUserCircle}
                    name="company_name"
                    label="Nome da empresa"
                    placeholder="Nome Empresa"
                  />

                  <InputEdit
                    icon={BiUserCircle}
                    name="responsible_name"
                    label="Nome Responsável"
                    placeholder="Nome do responsável"
                  />

                  <InputEdit
                    icon={AiOutlineMail}
                    name="email"
                    label="E-mail"
                    placeholder="E-mail"
                  />
                  <InputEdit
                    icon={FiPhone}
                    name="phone"
                    label="Telefone"
                    mask="(99) \9 9999-9999"
                    placeholder="Telefone"
                  />
                  <InputEdit
                    {...documentInputProps}
                    icon={AiOutlineIdcard}
                    name="document"
                    label="CPF / CNPJ"
                    placeholder="CPF / CNPJ"
                  />
                  <InputEdit
                    icon={AiOutlineIdcard}
                    name="responsible_activity"
                    label="Função do responsável"
                    placeholder="Função Responsável"
                  />
                  <InputEdit
                    icon={AiOutlineIdcard}
                    name="industry_activity"
                    label="Ramo de Atividade"
                    placeholder="Ramo de Atividade"
                  />
                  <InputEdit
                    icon={AiOutlineIdcard}
                    name="state_registration"
                    label="Inscrição Estadual"
                    placeholder="Inscrição Estadual"
                  />
                </Box>

                <Box>
                  <Scope path="address">
                    <InputEdit
                      icon={BiMapAlt}
                      name="zip_code"
                      label="CEP"
                      placeholder="CEP"
                    />
                    <InputEdit
                      icon={BiDirections}
                      name="street"
                      label="Rua"
                      placeholder="Rua"
                    />

                    <ScopedInput size={['50%']}>
                      <InputEdit
                        type="number"
                        icon={AiOutlineFieldNumber}
                        name="number"
                        label="Número"
                        placeholder="Número"
                      />
                      <InputEdit
                        icon={BiStreetView}
                        name="neighborhood"
                        label="Bairro"
                        placeholder="Bairro"
                      />
                    </ScopedInput>

                    <ScopedInput>
                      <InputEdit
                        icon={MdLocationCity}
                        name="city"
                        label="Cidade"
                        placeholder="Cidade"
                      />
                      <InputEdit
                        icon={GoLocation}
                        name="state"
                        label="Estado"
                        placeholder="Estado"
                      />
                    </ScopedInput>
                  </Scope>
                </Box>

                {editable && <SaveButton />}
              </CEPManager>
            </Form>
          </>
        )}
      </Container>
    );
  },
  {
    layout: BackHeaderLayout,
    cases: [Logged],
  },
);
