import { Scope } from '@unform/core';
import { useEffect, useContext, useCallback, useRef } from 'react';
import {
  AiOutlineMail,
  AiOutlineIdcard,
  AiOutlineFieldNumber,
} from 'react-icons/ai';
import {
  BiUserCircle,
  BiMapAlt,
  BiDirections,
  BiStreetView,
} from 'react-icons/bi';
import { FiPhone } from 'react-icons/fi';
import { GoLocation } from 'react-icons/go';
import { MdLocationCity } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Container, Box, ScopedInput } from './styles';

import { api } from '@modules/services/api';
import { apply } from '@resources/cases/apply';
import { Logged } from '@resources/cases/Logged';
import { useCPFOrCPNJInput } from '@resources/hooks/useCPFOrCPNJInput';
import { CEPManager } from '@resources/managers/CEPManager';
import { customerCreateSchema } from '@resources/schemas/customer';
import type { FormSubmit, FormHandles } from '@screen/components/forward/Form';
import { Input } from '@screen/components/ui/Input';
import { SaveButton } from '@screen/components/ui/SaveButton';
import {
  BackHeaderLayout,
  BackHeaderLayoutContext,
} from '@screen/layouts/BackHeaderLayout';

interface FormCustomerData {
  phone: string;
  email: string;
  document: string;
  full_name: string;
  address: {
    city: string;
    state: string;
    street: string;
    number: string;
    zip_code: string;
    neighborhood: string;
  };
}

export const CustomerCreate = apply(
  (): JSX.Element => {
    const formRef = useRef<FormHandles>(null);
    const documentInputProps = useCPFOrCPNJInput();

    const history = useHistory();
    const { setTitle } = useContext(BackHeaderLayoutContext);

    const handleSubmit: FormSubmit<FormCustomerData> = useCallback(
      async data => {
        const { data: customerId } = await api.post('customers', data);

        history.push(`/customers/${customerId}`);
        toast.success('Cliente cadastrado com sucesso!');
      },
      [history],
    );

    useEffect(() => {
      setTitle('Cadastro de cliente');
    }, [setTitle]);

    return (
      <Container
        ref={formRef}
        schema={customerCreateSchema}
        onSubmit={handleSubmit}
      >
        <CEPManager>
          <Box>
            <Input
              icon={BiUserCircle}
              name="full_name"
              placeholder="Nome Cliente / Nome Empresa"
            />
            <Input icon={AiOutlineMail} name="email" placeholder="E-mail" />
            <Input
              {...documentInputProps}
              icon={AiOutlineIdcard}
              name="document"
              placeholder="CPF / CNPJ"
            />
            <Input
              icon={BiUserCircle}
              name="company_name"
              placeholder="Razão Social"
            />

            <Input
              icon={FiPhone}
              name="phone"
              placeholder="Telefone"
              mask="(99) \9 9999-9999"
            />
          </Box>

          <Box>
            <Scope path="address">
              <Input icon={BiMapAlt} name="zip_code" placeholder="CEP" />
              <Input icon={BiDirections} name="street" placeholder="Rua" />
              <ScopedInput size={['50%']}>
                <Input
                  type="number"
                  icon={AiOutlineFieldNumber}
                  name="number"
                  placeholder="Número"
                />
                <Input
                  icon={BiStreetView}
                  name="neighborhood"
                  placeholder="Bairro"
                />
              </ScopedInput>
              <ScopedInput>
                <Input icon={MdLocationCity} name="city" placeholder="Cidade" />
                <Input icon={GoLocation} name="state" placeholder="Estado" />
              </ScopedInput>
            </Scope>
          </Box>

          <SaveButton />
        </CEPManager>
      </Container>
    );
  },
  {
    layout: BackHeaderLayout,
    cases: [Logged],
  },
);
