import { string, object, number } from 'yup';

export const customerCreateSchema = object().shape({
  phone: string().required('Informe um número de telefone'),
  document: string().required('Informe o CPF ou CNPJ do cliente'),
  full_name: string().required('Informe o nome do cliente'),
  email: string()
    .email('Insira um e-mail válido')
    .required('Informe o endereço de e-mail'),
  address: object().shape({
    complement: string(),
    street: string().required('Informe a Rua'),
    city: string().required('Informe a Cidade'),
    state: string().required('Informe o Estado'),
    neighborhood: string().required('Informe o Bairro'),
    zip_code: string().required('Informe o CEP da residência'),
    number: number()
      .required('Informe o Número Residencial')
      .default(0)
      .transform((currentValue, originalValue) => {
        if (typeof originalValue === 'string' && originalValue === '') {
          return undefined;
        }

        return currentValue;
      }),
  }),
});

export const customerEditSchema = object().shape({
  phone: string().required('Informe um número de telefone'),
  document: string().required('Informe o CPF ou CNPJ do cliente'),
  full_name: string().required('Informe o nome do cliente'),
  email: string()
    .email('Insira um e-mail válido')
    .required('Informe o endereço de e-mail'),
  address: object().shape({
    complement: string(),
    street: string().required('Informe a Rua'),
    city: string().required('Informe a Cidade'),
    state: string().required('Informe o Estado'),
    neighborhood: string().required('Informe o Bairro'),
    zip_code: string().required('Informe o CEP da residência'),
    number: number()
      .required('Informe o Número Residencial')
      .default(0)
      .transform((currentValue, originalValue) => {
        if (typeof originalValue === 'string' && originalValue === '') {
          return undefined;
        }

        return currentValue;
      }),
  }),
});
