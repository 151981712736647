import { Route, Switch } from 'react-router-dom';

import { useBuildRoute } from '@resources/hooks/useBuildRoute';
import { Products } from '@screen/pages/Products';
import { ProductCreate } from '@screen/pages/Products/pages/Create';
import { ProductEdit } from '@screen/pages/Products/pages/Edit';

export const ProductRoutes = (): JSX.Element => {
  const { make } = useBuildRoute('/products');

  return (
    <Switch>
      <Route path={make('/')} exact>
        <Products />
      </Route>

      <Route path={make('/create')}>
        <ProductCreate />
      </Route>

      <Route path={make('/:id')}>
        <ProductEdit />
      </Route>
    </Switch>
  );
};
