interface Shipping {
  type: number;
  amount: number;
}

export function getShippingValue(
  totalValue: number,
  shipping: Shipping,
): number {
  const { type, amount } = shipping;

  if (type === 0) {
    return amount;
  }

  return (totalValue / 100) * amount;
}
