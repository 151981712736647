import { useAsync, mergeClassNames } from '@hitechline/reactools';
import { Scope } from '@unform/core';
import {
  HOUSE_KIND,
  MARITAL_STATUS,
  HouseKindType,
  MaritalStatusType,
} from '@word/constants';
import { useContext, useState, useEffect, useRef, useCallback } from 'react';
import {
  AiOutlineMail,
  AiOutlineIdcard,
  AiOutlineFieldNumber,
} from 'react-icons/ai';
import {
  BiUserCircle,
  BiMapAlt,
  BiDirections,
  BiStreetView,
} from 'react-icons/bi';
import { FaShoppingCart, FaLongArrowAltRight } from 'react-icons/fa';
import { FiTrash2, FiPhone, FiX } from 'react-icons/fi';
import { GoLocation } from 'react-icons/go';
import { MdLocationCity } from 'react-icons/md';
import { TiPencil } from 'react-icons/ti';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Container,
  Form,
  DeleteModal,
  Options,
  Box,
  ScopedInput,
} from './styles';

import { Logged } from '@/resources/cases/Logged';
import {
  BackHeaderLayout,
  BackHeaderLayoutContext,
} from '@/screen/layouts/BackHeaderLayout';
import { api } from '@modules/services/api';
import { apply } from '@resources/cases/apply';
import { useCPFOrCPNJInput } from '@resources/hooks/useCPFOrCPNJInput';
import { CEPManager } from '@resources/managers/CEPManager';
import { sellerEditSchema } from '@resources/schemas/seller';
import type { FormSubmit, FormHandles } from '@screen/components/forward/Form';
import type { ModalHandles } from '@screen/components/forward/Modal';
import { Button } from '@screen/components/ui/Button';
import { InputEdit } from '@screen/components/ui/InputEdit';
import { SaveButton } from '@screen/components/ui/SaveButton';
import { Select, SelectOption } from '@screen/components/ui/Select';
import { Spinner } from '@screen/components/ui/Spinner';

export const SellerEdit = apply(
  (): JSX.Element => {
    const history = useHistory();
    const formRef = useRef<FormHandles>(null);
    const modalRef = useRef<ModalHandles>(null);

    const [editable, updateEditable] = useState(false);
    const [inDelete, updateInDelete] = useState(false);

    const { id } = useParams<{ id: string }>();
    const { setTitle } = useContext(BackHeaderLayoutContext);
    const documentInputProps = useCPFOrCPNJInput();

    const handleEdit = useCallback(() => {
      updateEditable(oldValue => !oldValue);
    }, []);

    const closeDeleteModal = useCallback(() => {
      modalRef.current?.close();
    }, []);

    const openDeleteModal = useCallback(() => {
      modalRef.current?.open();
    }, []);

    const getSellerData = useCallback(
      () =>
        api
          .get<SellerHealthyDocument>(`sellers/${id}`)
          .then(({ data }) => data),
      [id],
    );

    const {
      error,
      loading,
      data: sellerData,
    } = useAsync(() =>
      api.get<SellerHealthyDocument>(`sellers/${id}`).then(({ data }) => data),
    );

    const handleSubmit: FormSubmit<any> = useCallback(
      async data => {
        await api.put<SellerHealthyDocument>(`sellers/${id}`, data);

        updateEditable(false);
        toast.success(`${sellerData?.full_name} atualizado com sucesso`);

        const newSellerData = await getSellerData();

        formRef.current?.getUnformRef().current?.setData(newSellerData);
      },
      [id, sellerData, getSellerData],
    );

    const handleDelete = useCallback(async () => {
      updateInDelete(true);

      try {
        await api.delete<SellerHealthyDocument>(`sellers/${id}`);
        history.push('/sellers');
      } finally {
        closeDeleteModal();
        updateInDelete(false);
      }
    }, [id, history, closeDeleteModal]);

    useEffect(() => {
      setTitle('Detalhes Cliente');
    }, [setTitle]);

    useEffect(() => {
      if (error) {
        history.push('/sellers');
      }
    }, [error, history]);

    return (
      <Container>
        <DeleteModal ref={modalRef}>
          <button type="button" className="close" onClick={closeDeleteModal}>
            <FiX />
          </button>

          <p>
            Deseja continuar e deletar o vendedor{' '}
            <strong>{sellerData?.full_name}</strong>?
          </p>

          <Button
            onClick={handleDelete}
            className={mergeClassNames('continue', { loading: inDelete })}
          >
            <Spinner color="#fff" className="spinner" />
            <span>Continuar</span>
            <FaLongArrowAltRight />
          </Button>
        </DeleteModal>

        {loading ? (
          <Spinner size="30px" />
        ) : (
          <>
            <Options>
              <button type="button" onClick={handleEdit}>
                <TiPencil />
              </button>

              <button type="button" onClick={openDeleteModal}>
                <FiTrash2 />
              </button>

              <button type="button">
                <FaShoppingCart />
              </button>
            </Options>

            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              schema={sellerEditSchema}
              initialData={sellerData}
              className={mergeClassNames({ editable })}
            >
              <CEPManager>
                <Box>
                  <InputEdit
                    icon={BiUserCircle}
                    name="full_name"
                    label="Nome Vendedor"
                    placeholder="Nome Completo"
                  />
                  <InputEdit
                    icon={AiOutlineMail}
                    name="email"
                    label="E-mail"
                    placeholder="E-mail"
                  />
                  <Select
                    name="marital_status"
                    title="Estado civil"
                    icon={BiUserCircle}
                    options={maritalStatusOptions}
                  />
                  <InputEdit
                    {...documentInputProps}
                    name="document"
                    label="CPF"
                    mask="999.999.999-99"
                    placeholder="CPF"
                    icon={AiOutlineIdcard}
                  />

                  <InputEdit
                    name="phone"
                    label="Telefone Celular"
                    mask="(99) \9 9999-9999"
                    placeholder="Telefone Celular"
                    icon={FiPhone}
                  />
                  <InputEdit
                    icon={FiPhone}
                    mask="(99) \9 9999-9999"
                    label="Telefone Residencial"
                    name="house_phone"
                    placeholder="Telefone Residencial"
                  />
                </Box>

                <Box>
                  <Scope path="address">
                    <InputEdit
                      icon={BiMapAlt}
                      name="zip_code"
                      label="CEP"
                      placeholder="CEP"
                    />
                    <InputEdit
                      icon={BiDirections}
                      name="street"
                      label="Rua"
                      placeholder="Rua"
                    />

                    <ScopedInput size={['50%']}>
                      <InputEdit
                        icon={AiOutlineFieldNumber}
                        name="number"
                        label="Número"
                        placeholder="Número"
                      />
                      <InputEdit
                        icon={BiStreetView}
                        name="neighborhood"
                        label="Bairro"
                        placeholder="Bairro"
                      />
                    </ScopedInput>
                    <ScopedInput>
                      <InputEdit
                        icon={MdLocationCity}
                        name="city"
                        label="Cidade"
                        placeholder="Cidade"
                      />
                      <InputEdit
                        icon={GoLocation}
                        name="state"
                        label="Estado"
                        placeholder="Estado"
                      />
                    </ScopedInput>
                  </Scope>
                </Box>
                <Box>
                  <Select
                    name="house_kind"
                    title="Tipo de Residencia"
                    icon={MdLocationCity}
                    options={houseKindOptions}
                  />
                  <InputEdit
                    icon={BiUserCircle}
                    name="college"
                    label="Grau de Formação"
                    placeholder="Grau de Formação"
                  />
                </Box>

                {editable && <SaveButton />}
              </CEPManager>
            </Form>
          </>
        )}
      </Container>
    );
  },
  {
    layout: BackHeaderLayout,
    cases: [Logged],
  },
);

const maritalStatusOptions: SelectOption<MaritalStatusType>[] = Object.entries(
  MARITAL_STATUS,
).map(([value, name]) => ({
  label: name,
  value: Number(value) as MaritalStatusType,
}));

const houseKindOptions: SelectOption<HouseKindType>[] = Object.entries(
  HOUSE_KIND,
).map(([value, name]) => ({
  label: name,
  value: Number(value) as HouseKindType,
}));
